/******************************************************************************
*  @Filename: data-selector.component.ts
*  @Date: 27-10-2022
*  @Author: Adrien Lanco
*
*  Description:
*******************************************************************************/

import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-v5-data-selector',
  templateUrl: './v5-data-selector.component.html',
  styleUrls: ['./v5-data-selector.component.scss'],
})
export class V5DataSelectorComponent implements OnInit {
  @Input() widgetType: string;
  @Input() data: string;

  @Output() onDataChange = new EventEmitter<string>();

  private data_id: string;
  private dataTypeTabs: any;
  private dataType: string = null;
  private dataNumber: any = null;
  private dataChanel: any = null;


  constructor() { }

  ngOnInit() {
    if (this.data) {
      let split = this.data.split('-');
      this.dataType   = split[0]
      this.dataNumber = parseInt(split[1])
      this.dataChanel = parseInt(split[2])
    }
    if (this.widgetType == "BLINDS")
      this.dataType = "X4VR"
  }
  // 90 ipx 41 x8r  44 4vr en hexa
  public chanelChange(): void {
    if (this.dataType == "IPX") this.dataNumber = "0";
    if (this.dataType != '-1' && this.dataNumber != '-1' && this.dataChanel != '-1') {
      this.data_id = this.dataType+"-"+this.dataNumber+"-"+this.dataChanel
      this.onDataChange.emit(this.data_id)
    }
  }

}
