/******************************************************************************
*  @Filename: dashboard.component.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description: Dashboard page variables and functions
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: init
    * Name: reload
    * Name: changeEdit
    * Name: saveWidgetsSortList
    * Name: getWidgets
    * Name: initWidgets
    * Name: openAddWidgetModal
    * Name: widgetEdit
    * Name: widgetRemoved
    * Name: onGridListUpdate
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
  import { AlertController, ModalController } from '@ionic/angular';
  import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
  import {TranslateService} from '@ngx-translate/core';
  /***/

  /* Services */
  import { ApiService } from '../../../services/api/api.service';
  import { DataService } from '../../../services/data/data.service';
  import { SessionService } from '../../../services/session/session.service';
  import { WsService } from '../../../services/ws/ws.service';
  /***/

  /* Components */
  import { AddWidgetComponent } from '../../modals/add-widget/add-widget.component';
  /***/

  /* Node modules */
  import { filter } from 'rxjs/operators';
  import * as _ from 'lodash';
  /***/
/***/

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy  {

/* VARIABLES */
  private onLoad: boolean = true;

  private timeout: any[];
  private onLoadTime: any;

  public noDevice: boolean = false;
  public editMode: boolean = false;
  public optionsDrop: any = {};

  constructor(private router: Router,
              private route:ActivatedRoute,
              private alertController: AlertController,
              private modalController: ModalController,
              private api: ApiService,
              private ws: WsService,
              private data: DataService,
              private session: SessionService,
              private translate: TranslateService) {
  }
/***/

/* FUNCTIONS */
  async ngOnInit(): Promise<void> {
    await this.init();
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    if (!_.isEmpty(this.timeout)) {
      for (let i = 0; i < this.timeout.length; i++) {
        clearTimeout(this.timeout[i]);
      }
      this.timeout = [];
    }
  }

  /*
  * Name: init
  * Description: init dashboard
  *
  */
  public async init(): Promise<void>  {
    let user = this.session.getUser();
    if (user) {
      await this.reload();
    }
  }
  /***/

  /*
  * Name: reload
  * Description: Load/reload widgets list
  */
  public async reload(): Promise<void> {
    this.ws.init() // init websocket connexion with server
    .then(async () => {
      await this.data.init();
      let user = this.session.getUser();
      if (user) {
        if (_.isEmpty(this.data.userDevices)) this.noDevice = true;
        if (!_.isEmpty(this.timeout)) {
          for (let i = 0; i < this.timeout.length; i++) {
            clearTimeout(this.timeout[i]);
          }
          this.timeout = [];
        }

        let _this = this;
        this.optionsDrop = {
         filter: '.filtered',
         handle: '.gce-widget-grid-handle',
         onEnd: function (e) { _this.onGridListUpdate() },
         onMove: function (e) { return e.related.className.indexOf('filtered') === -1;  },
         preventOnFilter: false,
         disabled: this.editMode
        };
        try {
          this.onLoad = false;
          await this.initWidgets(); // await for widgets to be init
        } catch (err) {
          console.error(err);
        }
      }
    });
  }
  /***/

  /*
  * Name: changeEdit
  *
  */
  private changeEdit() {
    this.editMode = !this.editMode;
  }
  /***/

  /*
  * Name: saveWidgetsSortList
  * Description: Get user widgets
  *
  * Args:
  * - user (Object): User data
  *
  * Return (Object[]): User's widgets list
  */
  private saveWidgetsSortList(): Promise<any[]> {
    let user = this.session.getUser();
    return new Promise((resolve) => {
      this.api.request('PUT', '/api/users/'+user._id+'/widgetsSortList', {sortList:this.data.widgetsSortList})
      .then((ret) => {
        return resolve(ret);
      }).catch((err) => {
        if (err == "Unauthorized") this.session.unauthorized();
        else
        this.alertController.create({
          cssClass: 'system-alert',
          header: this.translate.instant("alert.widgetSaveErr"),
          message: this.translate.instant("alert.internal"),
          buttons: ['OK']
        }).then((alert) => {alert.present()});

        console.error(err);
        return resolve([]);
      });
    });
  }
  /***/

  /*
  * Name: getWidgets
  * Description: Get user widgets
  *
  * Args:
  * - user (Object): User data
  *
  * Return (Object[]): User's widgets list
  */
  private getWidgets(user): Promise<any[]> {
    return new Promise((resolve) => {
      this.api.request('GET', '/api/users/'+user._id+'/widgets', null)
      .then((ret) => {
        if (this.data.widgetsSortList) {
          let widgets = [];
          for (let i = 0; i < this.data.widgetsSortList.length; i++) {
            let widget = _.find(ret, { _id: this.data.widgetsSortList[i] });
            if (widget) widgets.push(widget);
          }
          return resolve(widgets);
        } else {
          if (_.isEmpty(ret)) ret = [];
          return resolve(ret);
        }
      }).catch((err) => {
        if (err == "Unauthorized") this.session.unauthorized();
        else
        this.alertController.create({
          cssClass: 'system-alert',
          header: this.translate.instant("alert.widgetLoadingErr"),
          message: this.translate.instant("alert.internal"),
          buttons: ['OK']
        }).then((alert) => {alert.present()});

        console.error(err);
        return resolve([]);
      });
    });
  }
  /***/


  /*
  * Name: initWidgets
  * Description: Get widgets values
  *
  */
  private async initWidgets(): Promise<void> {
    let date = Date.now();
    return new Promise((resolve, reject) => {
      for (let i = 0; i < this.data.widgets.length; i++) {
        this.ws.initWidget(this.data.widgets[i]).then(() => {
          if (Date.now() - date > 10000) {
            return resolve();
          } else if (_.isEmpty(this.data.pendingData)) {
            return resolve();
          }
        })
      }
    });
  }
  /***/

  /*
  * Name: openAddWidgetModal
  * Description: Open add widget modal
  */
  public openAddWidgetModal(): void {
    let user = this.session.getUser();

    this.modalController.create({
      component: AddWidgetComponent,
      cssClass: 'system-modal',
      componentProps: {
        'userId': user._id
      }
    }).then(async (modal) => {
      modal.present();

      modal.onWillDismiss().then(async (ret) => {
        this.editMode = false;
        if (ret.role == 'saved') { // Only after device registration
          await this.reload();
        }
      });
    });
  }
  /***/

  /*
  * Name: widgetEdit
  * Description: init widget after edit
  *
  * Args:
  * - widget (Object): Widget
  */
  public widgetEdit(id): void {
    let widget = _.find(this.data.widgets, {_id: id});
    if (widget != -1) {
      this.editMode = false;
      this.ws.initWidget(widget);
    }
  }
  /***/

  /*
  * Name: widgetRemoved
  * Description: Widget removed event handler
  *
  * Args:
  * - id (String): Widget ID
  */
  public widgetRemoved(id): void {
    let i = _.findIndex(this.data.widgets, {_id: id});
    if (i != -1) {
      this.data.widgets.splice(i, 1);
      this.onGridListUpdate();
    }
  }
  /***/

  /*
  * Name: onGridListUpdate
  * Description: preparing widgetsSortList before saving it
  *
  */
  public onGridListUpdate(): void {
    let newSortIndexes = [];
    if (this.data.widgets) {
      for (let i = 0; i < this.data.widgets.length; i++) {
        newSortIndexes.push(this.data.widgets[i]._id);
      }
      if (!_.isEqual(newSortIndexes,this.data.widgetsSortList)) {
        this.data.widgetsSortList = newSortIndexes;
        this.saveWidgetsSortList();
      }
    }
  }
  /***/
/***/
}
