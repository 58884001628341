/******************************************************************************
*  @Filename: widget-shutter.component.ts
*  @Date: 21-02-2022
*  @Author: Adrien Lanco
*
*  Description: Widget shutter content variables and functions
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: updateState
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, Input, OnInit } from '@angular/core';
  /***/

  /* Services */
  import { WsService } from '../../../../../services/ws/ws.service';
  /***/
/***/

const BLINDS_TIMEOUT = 5000;

@Component({
  selector: 'app-widget-shutter',
  templateUrl: './widget-shutter.component.html',
  styleUrls: ['./widget-shutter.component.scss'],
})
export class WidgetShutterComponent implements OnInit {

/* VARIABLES */
  @Input() public widget: any; // Widget data value

  public up: boolean = false;
  public down: boolean = false;
  public stop: boolean = false;

  public disUp: boolean = false;
  public disDown: boolean = false;

  private interOpen: any;
  private interStop: any;

  constructor(private ws: WsService) { }
/***/

/* FUNCTIONS */
  ngOnInit() {
    this.widget.value = {};
    if (this.widget.data_id['Position'] && this.widget.data_id['Position'][0])
      this.widget.value['Position'] = 50;

    this.widget.refresh = ((data, key) => {
      let val = (data._id < 100000 ? false : 50);
      if (!data.value && data.value !== false && data.value !== 0) {
        this.widget.errTrait[key] = true;
      }
      else {
        val = data.value;
        this.widget.errTrait[key] = false;
      }
      this.widget.value[key] = val;
      if (this.widget["onLoad"] && (this.widget["onLoad"][key] || this.widget["onLoad"][key] == false || this.widget["onLoad"][key] == 0))
         delete this.widget["onLoad"][key]

      if (key == "OpenClose" || key == "Position") {
        if (data.value === true || data.value === false) { this.up = false; this.down = false; }
        else if (data.value > 0)    { this.up = false; }
        else if (data.value < 100)  { this.down = false; }
        else if (data.value == 0)   { this.up = true; }
        else if (data.value == 100) { this.down = true; }
      }
    });
  }

  /*
  * Name: updateState
  * Description: Update widget state
  *
  * Args:
  * - state (Boolean): Current state
  *
  * Return (Boolean): Return new state
  */
  public sendUp(): void {
    if (this.widget.data_id['Position'] && this.widget.data_id['Position'][0]) {
      this.updatePosition('up')
    } else {
      this.up = true;
      this.down = false;
      this.stop = false;

      let id = this.widget.data_id["OpenClose"][0];
      if (!this.widget["onLoad"])this.widget["onLoad"] = {};
      this.widget.onLoad["OpenClose"] = true;

      let toSend = {
          from: "ihm",
          to: this.widget.deviceType,
          mac: this.widget.mac,
          _id: id,
          value: true,
          cmd: 'set'
      }
      if (this.interOpen) clearTimeout(this.interOpen);
      this.interOpen = setTimeout(() => {
        this.up = false;
        this.down = false;
        this.widget.value["OpenClose"] = false;
        if (this.widget.onLoad["OpenClose"]) delete this.widget.onLoad["OpenClose"];
      }, BLINDS_TIMEOUT);
      this.ws.send(toSend);
    }
  }

  public sendDown(): void {
    if (this.widget.data_id['Position'] && this.widget.data_id['Position'][0]) {
      this.updatePosition('down')
    } else {
      this.up = false;
      this.down = true;
      this.stop = false;

      let id;
      let value;
      if (this.widget.data_id["OpenClose"] && this.widget.data_id["OpenClose"][1]) {
        id = this.widget.data_id["OpenClose"][1];
        value = true;
      } else {
        id = this.widget.data_id["OpenClose"][0];
        value = false;
      }

      if (!this.widget["onLoad"])this.widget["onLoad"] = {};
      this.widget.onLoad["OpenClose"] = true;

      let toSend = {
          from: "ihm",
          to: this.widget.deviceType,
          mac: this.widget.mac,
          _id: id,
          value: value,
          cmd: 'set'
      }
      if (this.interOpen) clearTimeout(this.interOpen);
      this.interOpen = setTimeout(() => {
        this.up = false;
        this.down = false;
        this.widget.value["OpenClose"] = false;
        if (this.widget.onLoad["OpenClose"]) delete this.widget.onLoad["OpenClose"];
      }, BLINDS_TIMEOUT);
      this.ws.send(toSend);
    }
  }

  /*
  * Name: updateState
  * Description: Update widget state
  *
  * Args:
  * - state (Boolean): Current state
  *
  * Return (Boolean): Return new state
  */
  public updateState(): void {
    this.up = false;
    this.down = false;
    this.stop = true;

    let id = this.widget.data_id["StartStop"][0];
    if (!this.widget["onLoad"])this.widget["onLoad"] = {};
    this.widget.onLoad["StartStop"] = true;

    let toSend = {
        from: "ihm",
        to: this.widget.deviceType,
        mac: this.widget.mac,
        _id: id,
        value: true,
        cmd: 'set'
    }
    if (this.interStop) clearTimeout(this.interStop);
    this.interStop = setTimeout(() => {
      if (this.widget.onLoad["StartStop"]) delete this.widget.onLoad["StartStop"];
    }, BLINDS_TIMEOUT)
    this.ws.send(toSend);
  }
  /***/

  /*
  * Name: updateState
  * Description: Update widget state
  *
  * Args:
  * - state (Boolean): Current state
  *
  * Return (Boolean): Return new state
  */
  public updatePosition(value): void {
    let index;
    let valToSend = 0;

    if (!this.down && value == "down" || !this.up && value == "up" || this.widget.value["OpenClose"] == false) {
      this.widget.value["OpenClose"] = false;
      if (value == "up") {
        valToSend = 0;
        this.up = true;
        this.down = false;
        this.stop = false;
      } else {
        valToSend = 100;
        this.up = false;
        this.down = true;
        this.stop = false;
      }
      let id = this.widget.data_id["OpenClose"];
      if (!this.widget["onLoad"])this.widget["onLoad"] = {};
      this.widget.onLoad["OpenClose"] = true;
      let toSend = {
          from: "ihm",
          to: this.widget.deviceType,
          mac: this.widget.mac,
          _id: id,
          value: valToSend,
          cmd: 'set'
      }

      if (this.interOpen) clearTimeout(this.interOpen);
      this.interOpen = setTimeout(() => {
        this.up = false;
        this.down = false;
        this.widget.value["OpenClose"] = false;
        if (this.widget.onLoad["OpenClose"]) delete this.widget.onLoad["OpenClose"];
      }, BLINDS_TIMEOUT);
      this.ws.send(toSend);
    }
  }
  /***/
/***/
}
