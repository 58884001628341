/******************************************************************************
*  @Filename: data.service.ts
*  @Date: 03-02-2022
*  @Author: Adrien Lanco
*
*  Description: contain all data asked to the server
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: init
    * Name: initData
    * Name: destroy
    * Name: getUser
    * Name: getUserDevices
    * Name: getWidgets
    * Name: getWidgetsSortList
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Injectable, OnInit } from '@angular/core';
  import { AlertController, ModalController } from '@ionic/angular';
  import {TranslateService} from '@ngx-translate/core';
  /***/

  /* Services */
  import { ApiService } from '../api/api.service';
  import { SessionService } from '../session/session.service';
  import { ColorService } from '../color/color.service';
  /***/

  /* Node modules */
  import * as _ from 'lodash';
  /***/
/***/

@Injectable({
  providedIn: 'root'
})
export class DataService implements OnInit {

/* VARIABLES */
  public user;
  public widgets: any[];
  public widgetsSortList: any[];

  public pendingData: any = {};


  public userDevices: any[] = [];


  constructor(private alertController: AlertController,
              private modalController: ModalController,
              private api: ApiService,
              public session: SessionService,
              public color: ColorService,
              private translate: TranslateService) {}
/***/

/* FUNCTIONS */
  async ngOnInit() {
    await this.init();
  }

  /*
  * Name: init
  * Description: get all users datas from server
  */
  async init(): Promise<void> {
    return new Promise(async (resolve) => {
      let user = this.session.getUser();
      if (user) {
        this.user = await this.getUser();
        this.userDevices = await this.getUserDevices();
        this.widgetsSortList = await this.getWidgetsSortList();
        this.widgets = await this.getWidgets();
      }
      return resolve()
    });
  }
  /***/

  /*
  * Name: initData
  * Description: get specific users datas from server
  *
  * Args: type (string): the type of data we want to get
  */
  async initData(type) {
    switch (type) {
      case "user":
        this.user = await this.getUser();
        break;
      case "widgets":
        this.widgetsSortList = await this.getWidgetsSortList();
        this.widgets = await this.getWidgets();
        break;
      case "devices":
        this.userDevices = await this.getUserDevices();
        break;
    }
    if (type != "user") {
      if (!this.user) this.user = await this.getUser();
      else this.color.setTheme(this.user.theme || "light");
    }
  }

  /*
  * Name: destroy
  * Description: destroy all users data
  */
  destroy() {
    this.user = null;
    this.widgetsSortList = null;
    this.widgets = null;
    this.userDevices = null;
  }
  /***/

  /*
  * Name: getUser
  * Description: Get user data
  *
  * Return (Object): User data
  */
  public async getUser(): Promise<any> {
    return new Promise((resolve) => {
      let user = this.session.getUser();

      this.api.request('GET', '/api/users/'+user._id, null)
      .then((ret) => {
        this.color.setTheme(ret.theme || "light");
        return resolve(ret);
      }).catch((err) => {
        if (err == "Unauthorized") this.session.unauthorized();
        else
          this.alertController.create({
            cssClass: 'system-alert',
            header: this.translate.instant('alert.userLoadingErr'),
            message: this.translate.instant('alert.internal'),
            buttons: ['OK']
          }).then((alert) => {alert.present()});
        console.error(err);
        return resolve(null);
      });
    });
  }
  /***/

  /*
  * Name: getUserDevices
  * Description: Get devices associated to connected user
  *
  * Return (Object[]): User devices
  */
  public async getUserDevices(): Promise<any[]> {
    return new Promise((resolve) => {
      let user = this.session.getUser();

      this.api.request('GET', '/api/users/'+user._id+'/devices', null)
      .then((ret) => {
        return resolve(ret);
      }).catch((err) => {
        if (err == "Unauthorized") this.session.unauthorized();
        else
          this.alertController.create({
            cssClass: 'system-alert',
            header: this.translate.instant('alert.devicesLoadingErr'),
            message: this.translate.instant('alert.internal'),
            buttons: ['OK']
          }).then((alert) => {alert.present()});

        console.error(err);
        return resolve([]);
      });
    });
  }
  /***/

  /*
  * Name: getWidgets
  * Description: Get user widgets
  *
  * Args:
  * - user (Object): User data
  *
  * Return (Object[]): User's widgets list
  */
  public async getWidgets(): Promise<any[]> {
    return new Promise((resolve) => {
      let user = this.session.getUser();
      this.api.request('GET', '/api/users/'+user._id+'/widgets', null)
      .then((ret) => {
        if (this.widgetsSortList) {
          let widgets = [];
          for (let i = 0; i < this.widgetsSortList.length; i++) {
            let widget = _.find(ret, { _id: this.widgetsSortList[i] });
            if (widget) {
              if (_.find(this.userDevices, (el) => {return _.isEqual(el.mac, widget.mac)})) {
                for (let i = 0; i < this.userDevices.length; i++) {
                  if (this.userDevices[i].mac == widget.mac) {
                    if (this.userDevices[i].status == "online") widget.err = false;
                    else widget.err = true;
                  }
                }
                widget.noDevice = false;
              } else {
                widget.noDevice = true;
              }
              widget.errTrait = {};
              for (const trait in widget.data_id) {
                widget.errTrait[trait] = false;
              }
              widgets.push(widget);
            }
          }
          return resolve(widgets);
        } else {
          ret = [];
          return resolve(ret);
        }
      }).catch((err) => {
        if (err == "Unauthorized") this.session.unauthorized();
        else
          this.alertController.create({
            cssClass: 'system-alert',
            header: this.translate.instant('alert.widgetLoadingErr'),
            message: this.translate.instant('alert.internal'),
            buttons: ['OK']
          }).then((alert) => {alert.present()});

        console.error(err);
        return resolve([]);
      });
    });
  }
  /***/


  /*
  * Name: getWidgetsSortList
  * Description: Get user widgets
  *
  * Args:
  * - user (Object): User data
  *
  * Return (Object[]): User's widgets list
  */
  public async getWidgetsSortList(): Promise<any[]> {
    return new Promise((resolve) => {
      let user = this.session.getUser();

      this.api.request('GET', '/api/users/'+user._id+'/widgetsSortList', null)
      .then((ret) => {
        return resolve(ret.sortList);
      }).catch((err) => {
        if (err == "Unauthorized") this.session.unauthorized();
        else
          this.alertController.create({
            cssClass: 'system-alert',
            header: this.translate.instant('alert.widSortLoadingErr'),
            message: this.translate.instant('alert.internal'),
            buttons: ['OK']
          }).then((alert) => {alert.present()});

        console.error(err);
        return resolve([]);
      });
    });
  }
  /***/
/***/
}
