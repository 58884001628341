/******************************************************************************
*  @Filename: forget-password.component.ts
*  @Date: 02-02-2022
*  @Author: Adrien Lanco
*
*  Description: forget-password page variables and function
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Component
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: sendResetMail
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit } from '@angular/core';
  import { AlertController, ModalController } from '@ionic/angular';
  import {TranslateService} from '@ngx-translate/core';
  /***/

  /* Services */
  import { ApiService } from '../../../services/api/api.service';
  /***/

  /* Component */
  /***/
/***/

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {

/* VARIABLES */
  public email: string;

  constructor(private alertController: AlertController,
              private modalController: ModalController,
              private api: ApiService,
              private translate: TranslateService) {
  }
/***/

/* FUNCTIONS */
  ngOnInit() {}

  /*
  * Name: sendResetMail
  * Description: send mail with link to reset password
  *
  * Args:
  * - mail (String): email to send the mail
  */
  public sendResetMail(mail) {
    mail = mail.toLowerCase();
    let toSend = {
      mail: mail
    }
    this.api.request('POST', '/api/users/forgetPass', toSend).
    then((ret) => {
      this.modalController.dismiss();
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.sendMail'),
        message: this.translate.instant('alert.sendMailMess')+mail,
        buttons: ['OK']
      }).then((alert) => {alert.present()});
    }).catch((err) => {
      if (err == 'User not found') err = "userNotFound";
      else err = "internal";
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.sendMailError'),
        message: this.translate.instant('alert.'+err),
        buttons: ['OK']
      }).then((alert) => {alert.present()});
    })
  }
  /***/
/***/
}
