/******************************************************************************/
/*  Name: devices.modules.ts                                                  */
/*  Date: 29/10/2021                                                          */
/*  Author: Jbristhuille                                                      */
/*                                                                            */
/*  Description: Devices modules, manage devices registration                 */
/******************************************************************************/

/* SUMMARY
  * Angular
  * Modules
  * Components
*/

/* Angular */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { ComponentModule } from '../components/component.module';
/***/

/* Modules */
import { ServicesModule } from '../services/services.module';
/***/

/* Components */
import { DevicesComponent } from './components/devices/devices.component';
import { AddDeviceComponent } from './modals/add-device/add-device.component';
import { EditDeviceComponent } from './modals/edit-device/edit-device.component';
import { RemoveDeviceComponent } from './modals/remove-device/remove-device.component';
/***/

@NgModule({
  declarations: [
    DevicesComponent,
    AddDeviceComponent,
    EditDeviceComponent,
    RemoveDeviceComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ServicesModule,
    TranslateModule,
    ComponentModule
  ],
  providers: [
    DevicesComponent
  ],
  exports: [
    DevicesComponent
  ]
})
export class DevicesModule {}
