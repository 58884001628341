/******************************************************************************
*  @Filename: add-device.component.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description: Add device modal variables and functions
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Component
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: add
    * Name: checkRequired
*/

/* Angular */
import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
/***/

/* Services */
import { ApiService } from '../../../services/api/api.service';
/***/

/* Node modules */
import * as _ from 'lodash';
/***/

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss'],
})
export class AddDeviceComponent implements OnInit {

/* VARIABLES */
  @Input() public descriptors: any;
  @Input() public userId: string;

  public selected: any = "";
  public device: any;

  public actualStep: number = 1;
  public nbStep: number = 1;

  constructor(private modalController: ModalController,
              private alertController: AlertController,
              private api: ApiService,
              private translate: TranslateService) {
  }
/***/

/* FUNCTIONS */
  ngOnInit() {
    this.device = {
      name: "",
      mac: "",
      code: ""
    };
  }

  public typeChange(event): void {
    this.selected = event;
    for (let t = 0; t < this.descriptors.length; t++) {
      if (this.descriptors[t].type == this.selected.type) {
        this.nbStep = this.descriptors[t].config.length;
        break;
      }
    }
  }

  /*
  * Name: add
  * Description: Add new devices
  *
  * Args:
  * - selected (String): Selected device descriptor
  * - device (Object): Device data
  */
  public add(selected, device): void {
      let data = {
        type: selected.type,
        config: _.cloneDeep(device)
      };

      this.api.request('POST', '/api/users/'+this.userId+'/devices', data)
      .then((ret) => {
        this.modalController.dismiss(ret, 'saved'); // Return registered device
      }).catch((err) => {
        if (err == "Device already registered on your account") err = "devAlreadyRegisY";
        else if (err == "Device already registered by another account") err = "devAlreadyRegisA";
        else if (err == "User not found") err = "userNotFound";
        else if (err == "Invalid code") err = "deviceInvalidCode";
        else if (err == "Device not found") err = "deviceNotFound";
        else if (err = "Max devices reache") err = "maxDeviceReach";
        else err = "internal";

        this.alertController.create({
          cssClass: 'system-alert',
          header: this.translate.instant('alert.devAddErr'),
          message: this.translate.instant('alert.'+err),
          buttons: ['OK']
        }).then((alert) => {alert.present()});

        console.error(err);
      });
  }
  /***/

  /*
  * Name: isInvalidStep
  * Description: check if the modal step is complete
  *
  * Return: true if the step is not complete
  */
  public isInvalidStep(): boolean {
    let ret;

    switch (this.actualStep) {
      case 1: ret = this.selected ? false : true; break;
      case 2: ret = this.device.name ? false : true; break;
      case 3: ret = this.device.mac ? false : true; break;
      case 4: ret = this.device.device ? false : true; break;
    }
    return ret;
  }
  /***/

  /*
  * Name: isInvalid
  * Description: check if the device is not in error
  *
  * Return: true if the device is in error
  */
  public isInvalid(): boolean {
    if (this.selected) {
      let config;
      for (let t = 0; t < this.descriptors.length; t++) {
        if (this.descriptors[t].type == this.selected.type) {
          config = this.descriptors[t].config;
          break;
        }
      }

      if (config) {
        for (let i = 0; i < config.length; i++) {
          if (!this.device[config[i].id] && config[i].id != "status")
            return true
        }
        return false
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  /***/
/***/
}
