/******************************************************************************
*  @Filename: toggle.component.ts
*  @Date: 05-07-2022
*  @Author: Adrien Lanco
*
*  Description:
*******************************************************************************/

import { Component, Input, Output, HostBinding, OnInit, EventEmitter } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit {

  constructor(private tr: TranslateService) { }

  @Input() public value: boolean;
  @Output() public valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public disabled: boolean;

  ngOnInit() {
  }

  public changeValue(): void {
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }
}
