/******************************************************************************/
/*  Name: thirdparty.module.ts                                                */
/*  Date: 15/11/2021                                                          */
/*  Author: Jbristhuille                                                      */
/*                                                                            */
/*  Description: Thirdparty module (Google home, Alexa, etc...)               */
/******************************************************************************/

/* SUMMARY
  * Angular
*/

/* Angular */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'
/***/

/* Modules */
import { ServicesModule } from '../services/services.module';
/***/

/* Components */
import { LinkComponent } from './link/link.component';
/***/

@NgModule({
  declarations: [LinkComponent],
  imports: [CommonModule, FormsModule, ServicesModule],
  exports: [LinkComponent]
})
export class ThirdpartyModule {
}
