/******************************************************************************
*  @Filename: ws.service.ts
*  @Date: 07-01-2022
*  @Author: Adrien Lanco
*
*  Description: Ensure websocket connection with server
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: init
    * Name: onDataReceived
    * Name: send
    * Name: updateDevice
    * Name: updateGoogle
    * Name: updateDashboard
    * Name: initWidget
    * Name: checkPendingData
    * Name: getDevType
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Injectable } from '@angular/core';
  /***/

  /* Services */
  import { SessionService } from '../../services/session/session.service';
  import { DataService } from '../../services/data/data.service';
  /***/

  /* Node modules */
  import { w3cwebsocket as W3CWebSocket } from "websocket";
  import * as _ from 'lodash';
  import { environment } from '../../../environments/environment';
  /***/
/***/

@Injectable({
  providedIn: 'root'
})
export class WsService {

/* VARIABLES */
  public ws: any;
  public onLoad: boolean = true;
  public disconnected: boolean = true;

  public dashWidgetsInit: any;

  private timeout: any[];
  constructor(private data: DataService,
              private session: SessionService) { }
/***/

/* FUNCTIONS */

  /*
  * Name: init
  * Description: Initialize connections
  */
  public async init() {
    if (!this.disconnected)
      return
    else
    return new Promise<void>((resolve) => {
      let user = this.session.getUser();
      if (user) {
        let ws = environment.production ? environment.ws : environment.ws_dev;
        const ihm = new W3CWebSocket(ws);

        if (ihm) {
          ihm.onopen = ((err) => { // Error event
            ihm.send(JSON.stringify({
              event: "connect",
              data: JSON.stringify({
                token: user.token,
              })
            }))
          });

          ihm.onmessage = (data) => {
            if (data.data == "connected") {
              this.disconnected = false;
              this.onLoad = false;
              return resolve();
            } else {
              this.onDataReceived(data)
            }
            let user = this.session.getUser();
            if (!user) ihm.close()
          };

          ihm.onclose = () => {
            if (!_.isEmpty(this.timeout)) {
              for (let i = 0; i < this.timeout.length; i++) {
                clearTimeout(this.timeout[i]);
              }
              this.timeout = [];
            }
            this.disconnected = true;
          }

          this.ws = ihm;
          this.session.wsClose = (() => {
            this.disconnected = true;
            this.ws.close();
          });
        } else {
          process.exit(1);
          return resolve()
        }
      }
    });
  }
  /***/

  /*
  * Name: onDataReceived
  * Description: handle data from server
  */
  public async onDataReceived(data): Promise<any> {
    return new Promise((resolve, reject) => {
      data = JSON.parse(data.data);
      if (data._id == "G_STATUS") {
        this.updateGoogle(data);
      } else if (data._id == "STATUS") {
        this.updateDevice(data);
      } else if (data._id){
        this.updateDashboard(data);
      }
      if (this.disconnected == true)
        return resolve({status: "connect"});
      else
        return resolve({status: "mess"});
    });

  }
  /***/

  /*
  * Name: send
  * Description: send data to IHM
  */
  public async send(data): Promise<void> {
    let ihm = this.ws;
    if (ihm && (data.value || data.value === false || data.value === 0)) {
      ihm.send(JSON.stringify({
        event: "events",
        data: JSON.stringify(data)
      }));
    }
  }
  /***/

  /*
  * Name: updateDevice
  * Description: update device STATUS
  */
  public updateDevice(data) {
    for (let i = 0; i < this.data.userDevices.length; i++) {
      if (this.data.userDevices[i].mac == data.mac) {
        this.data.userDevices[i].status = data.value; // set device STATUS
        for (let j = 0; j < this.data.widgets.length; j++) {
          if (this.data.widgets[j].mac == data.mac) {
            if (this.data.userDevices[i].status == "online") this.initWidget(this.data.widgets[j]); // init widgets
            this.data.widgets[j].err = (this.data.userDevices[i].status == "online" ? false : true); // clear widget error
          }
        }
      }
    }
  }

  /*
  * Name: updateGoogle
  * Description: Update linked status
  */
  public updateGoogle(data) {
    if (data.value == "linked")
      this.data.user.gShortLiveToken = data.mac
  }

  /*
  * Name: updateDashboard
  * Description: update widgets data on dashboard
  */
  public async updateDashboard(data) {
    if (data["cmd"] && data["cmd"] == "init") { // the datas are a response of an init cmd
      if (this.data.pendingData[data.mac] && this.data.pendingData[data.mac][data._id]
          && this.data.pendingData[data.mac][data._id]["cmd"] == "init") { // the data is still in pending
          this.data.pendingData[data.mac][data._id]["updatedData"] = data.value; // update the value
      }
    } else if (data["cmd"] && data["cmd"] == "GETIDs") { // the datas are a response of an init cmd
      if (this.data.pendingData[data.mac] && this.data.pendingData[data.mac]["GETIDs"]) { // the data is still in pending
        this.data.pendingData[data.mac]["GETIDs"]["updatedData"] = data.value; // update the value
      }
    } else if (this['data'] && this['data']['widgets']){ // the datas are a changing state from a card
      if (!this.data.widgets) this.data.widgets = [];
      this.data.widgets.forEach(widget => {
        if (widget.mac == data.mac) {                //for each widgets
          for (let key in widget.data_id) {                     //for each traits
            let ids = widget.data_id[key];
            for (let i = 0; i < ids.length; i++) {                    //for each ids by trait
              if (!widget["value"] && widget["value"] != false) widget["value"] = {}
              if (data._id == ids[i]) {
                if (widget.refresh) { // the concerned widget have a refresh function
                  widget.refresh(data, key)
                } else { // generic handler for all widgets to update data
                  let val = this.getDefaultValueFromType(key);
                  if (!data.value && data.value !== false && data.value !== 0) { // there is no data
                    widget.errTrait[key] = true; // this data on this widget is on error
                  } else {
                    val = data.value;
                    widget.errTrait[key] = false; // this data on this widget is not on error
                  }
                  widget.value[key] = val; // set the widget val
                  if (widget["onLoad"] && (widget["onLoad"][key] || widget["onLoad"][key] == false || widget["onLoad"][key] == 0)
                     && widget["onLoad"][key] == val)
                     delete widget["onLoad"][key] // removing loading state on this data on this widget
                }
              }
            }
          }
        }
      });
    }
  }
  /***/

  /*
  * Name: initWidget
  * Description: ask to server the value of the data
  *              binded on each widgets
  */
  public async initWidget(widget): Promise<void> {
    let devType = this.getDevType(widget.mac);
    let idsTab = [];
    let type = {};
    for (let key in widget.data_id) {       //for each traits
      let ids = widget.data_id[key];
      for (let j = 0; j < ids.length; j++) {//for each ids by trait
        if (ids[j] != "") { // the id for this trait is define
          idsTab.push(ids[j]); // push it to the ids tab
          if (!type[widget._id]) type[widget._id] = [];
          type[widget._id].push(key); // push the refered trait
        }
      }
    }
    let update = {
      from: "ihm",
      to: devType,
      mac: widget.mac,
      _id: widget._id,
      value: idsTab,
      cmd: 'init'
    }
    if (!this.data.pendingData[widget.mac]) this.data.pendingData[widget.mac] = {};
      this.data.pendingData[widget.mac][widget._id] = { // saving request in pending data
        onPending: idsTab,
        updatedData: "onPending",
        cmd: "init"
    };
    this.send(update); // send request

    return new Promise(async (resolve, reject) => {
      try {
        let date = Date.now();
        if (_.isEmpty(this.data.widgets)) return resolve();

        this.checkPendingData(widget.mac , widget._id, 0)
        .then((dataState) => { // on data updated
          dataState = dataState.value;
          for (let k = 0; k < type[widget._id].length; k++) {
            if (!widget.value && widget.value != false) widget.value = {};
            let val = this.getDefaultValueFromType(type[widget._id][k]);

            if (dataState[0] == 'ERROR') { // the data is on timeout
              if (_.isEmpty(widget.value[type[widget._id][k]])) {
                widget.value[type[widget._id][k]] = val; // set default val
                widget.errTrait[type[widget._id][k]] = true; // set error to true on this trait on this widget
              }
            } else {
              if (!dataState[k] && dataState[k] != false && dataState[k] != 0) { // updated data is not valid
                widget.value[type[widget._id][k]] = val; // set default val
                widget.errTrait[type[widget._id][k]] = true; // set error to true on this trait on this widget
              } else {
                widget.value[type[widget._id][k]] = dataState[k]; // set value from updated
                widget.errTrait[type[widget._id][k]] = false; // set error to false on this trait on this widget
              }
            }
          }
          if (Date.now() - date > 5000) { // timeout for the non updated data
            return resolve(); // resolve cause one or more data could have been updated
          } else if (_.isEmpty(this.data.pendingData[widget.mac])) { // there is no data in pending anymore
            return resolve(); // resolve all data updated
          }
        });
      } catch (err) {
        return reject("Init Error: "+err);
      }
    });
  }
  /***/

  /*
  * Name: initWidget
  * Description: ask to server the value of the data
  *              binded on each widgets
  */
  public async getV5Ids(widget, update): Promise<void> {
    let mac = widget.device.mac;
    if (!this.data.pendingData[mac]) this.data.pendingData[mac] = {};
    this.data.pendingData[mac]["GETIDs"] = { // saving request in pending data
      onPending: update.value,
      updatedData: "onPending",
      cmd: "GETIDs"
    };
    this.send(update); // send request

    return new Promise(async (resolve, reject) => {
      try {
        this.checkPendingData(mac , "GETIDs", 0)
        .then((dataState) => { // on data updated
          dataState = dataState.value;
          if (dataState[0] == 'ERROR') { // the data is on timeout
            return reject("Timeout")
          } else if (dataState[0] == 'INVALID ID') { // the data is on timeout
            return reject("Invalid ID")
          } else {
            return resolve(dataState)
          }
        });
      } catch (err) {
        return reject("Init Error: "+err);
      }
    });
  }
  /***/

  /*
  * Name: checkPendingData
  * Description: await for pending data to change
  *
  * Args:
  * - data (Object): Data from Google
  *  - requestId(String): ID of the request.
  *  - deviceId	(String): id of the googlehome device
  *
  */
  private async checkPendingData(mac, data_id, nbTry): Promise<any>{
    let timeBtwCheck = 50; //time between to check
    let timeout = 2000;    //timeout before return to google that the device is disconnected

    if(!this.data.pendingData[mac] || !this.data.pendingData[mac][data_id]) { // the data is not in pending anymore
      if(_.isEmpty(this.data.pendingData[mac])) delete this.data.pendingData[mac]
      return ({ value: ["ERROR"] }); // return error
     }
    if(this.data.pendingData[mac][data_id]["updatedData"] === "onPending" && nbTry * timeBtwCheck < timeout) {//the data still in pending
      return new Promise(async (resolve) => {
        if (!this.timeout) this.timeout = [];
        this.timeout.push(setTimeout((async () => {
          nbTry++;
          this.checkPendingData(mac, data_id, nbTry)
          .then((ret) => {
            if (ret) resolve(ret);
          });
        }), timeBtwCheck));
      });
    }
    if (nbTry * timeBtwCheck >= timeout) { //the data don't change before timeout
      delete this.data.pendingData[mac][data_id];
      if(_.isEmpty(this.data.pendingData[mac])) delete this.data.pendingData[mac]
      return ({ value: ["ERROR"] });
    } else if (this.data.pendingData[mac][data_id]["updatedData"] !== "onPending"){ //the data change before timeout
      let val = this.data.pendingData[mac][data_id]["updatedData"];
      delete this.data.pendingData[mac][data_id];
      if(_.isEmpty(this.data.pendingData[mac])) delete this.data.pendingData[mac]
      return { value: val };
    }
  }
  /***/

  /*
  * Name: getDevType
  * Description: get device type
  *
  * return:
  * - type (String): ex ipx800v5
  */
  public getDevType(mac): void {
    for (let i = 0; i < this.data.userDevices.length; i++) {
      if (this.data.userDevices[i].mac == mac)
        return this.data.userDevices[i].type;
    }
  }
  /***/

  public getDefaultValueFromType(trait): any {
    switch (trait) {
      case 'OnOff':
      case 'OpenClose':
      case "StartStop":
        return false;
        break;
      case 'Brightness':
      case 'Position':
        return 50;
        break;
      default:
        return false;
        break;
    }
  }
}
