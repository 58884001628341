/******************************************************************************
*  @Filename: color.service.ts
*  @Date: 15-03-2022
*  @Author: Adrien Lanco
*
*  Description: used to change theme for user from light to dark
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Component
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: setTheme
    * Name: changeTheme
    * Name: colorTest
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Injectable } from '@angular/core';
  /***/
/***/

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  constructor() { }

/* FUNCTIONS */

  /*
  * Name: setTheme
  */
  public setTheme(theme){
    let systemDark = window.matchMedia("(prefers-color-scheme: "+theme+")");
    systemDark.addListener(this.colorTest);
    document.body.setAttribute('data-theme', theme);
  }
  /***/

  /*
  * Name: changeTheme
  */
  public changeTheme(event){
    let systemDark = window.matchMedia("(prefers-color-scheme: dark)");
    systemDark.addListener(this.colorTest);
    if(event.detail.checked){
      document.body.setAttribute('data-theme', 'dark');
    }
    else{
      document.body.setAttribute('data-theme', 'light');
    }
  }
  /***/

  /*
  * Name: colorTest
  */
  private colorTest(systemInitiatedDark) {
    if (systemInitiatedDark.matches) {
      document.body.setAttribute('data-theme', 'dark');
    } else {
      document.body.setAttribute('data-theme', 'light');
    }
  }
  /***/
/***/
}
