/******************************************************************************
*  @Filename: app.component.ts
*  @Date: 28/10/2021
*  @Author: Jbristhuille
*
*  Description: App main variables and functions
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: disconnect
    * Name: getTitle
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit } from '@angular/core';
  import { Router } from '@angular/router';
  /***/

  /* Services */
  import { WsService } from './services/ws/ws.service';
  import { SessionService } from './services/session/session.service';
  import { TranslateService } from '@ngx-translate/core';
  /***/
/***/

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

/* VARIABLES */
  public accountMenu = false;

  public appPages = [
    { title: 'dashboard', url: '/dashboard', icon: 'speedometer' },
    { title: 'devices', url: '/devices', icon: 'cube'}
  ];

  constructor(private translate: TranslateService,
              private router: Router,
              public session: SessionService,
              public ws: WsService) {
    translate.setDefaultLang('fr');
  }
/***/

/* FUNCTIONS */
  ngOnInit() {

  }

  /*
  * Name: disconnect
  * Description: close websocket before disconecting
  */
  public disconnect() {
    if (this.ws.ws)
      this.ws.ws.close();
    this.session.disconnect(false);
  }
  /***/

  /*
  * Name: getTitle
  * Description: get page title
  */
  public getTitle() {
    let url = this.router.url;
    let tmp = url.split("/");
    url = tmp[tmp.length-1];
    tmp = url.split("?");
    url = tmp[0];
    return url;
  }
  /***/
/***/
}
