/******************************************************************************
*  @Filename: reset-password.component.ts
*  @Date: 02-02-2022
*  @Author: Adrien Lanco
*
*  Description: SignIn page variables and functions
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Component
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: register
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit } from '@angular/core';
  import { Router, ActivatedRoute } from '@angular/router';
  import { AlertController } from '@ionic/angular';
  import {TranslateService} from '@ngx-translate/core';
  /***/

  /* Services */
  import { ApiService } from '../../../services/api/api.service';
  /***/
/***/

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

/* VARIABLES */
  public email: String;
  private token: String;
  public passwd: String;
  public confirm: String;

  public onLoad: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private alertController: AlertController,
              private api: ApiService,
              private translate: TranslateService) {
  }
/***/

/* FUNCTIONS */
  ngOnInit() {
    this.onLoad = true;
    let params = this.route.snapshot.queryParams
    if (params) {
      if (params.token) {
        this.token = params.token;
        this.api.request('GET', '/api/users/forgetPass/'+params.token, {})
        .then((ret) => {
          this.email = ret.login;
          this.onLoad = false;
        }).catch((err) => {
          if (err == "The link has expired or this password has already been changed.") {
            err = "linkExpired";
          } else {
            err = "internal";
          }
          this.alertController.create({
            cssClass: 'system-alert',
            header: this.translate.instant('alert.invalidLink'),
            message: this.translate.instant('alert.'+err),
            buttons: ['OK']
          }).then((alert) => {alert.present()});
          this.router.navigate(['/login']);
        });
      }
    }
  }

  /*
  * Name: updatePass
  * Description: send updated user password
  *
  * Args:
  * - passwd (String): Account password
  * - confirm (String): Password confirmation
  */
  public updatePass(passwd, confirm): void {
    if (this.email && passwd && confirm && passwd == confirm) {
      this.onLoad = true;

      let data = {
        login: this.email,
        password: String(passwd)
      }

      this.api.request('PUT', '/api/users/forgetPass/'+this.token, data)
      .then((ret) => {
        this.alertController.create({
          cssClass: 'system-alert',
          header: this.translate.instant('alert.userSaved'),
          message: this.translate.instant('alert.userSavedPass'),
          buttons: ['OK']
        }).then((alert) => {alert.present()});
        this.router.navigate(['/login']);
      }).catch((err) => { // Catch API error
        if (err == "User not found") err = "userNotFound";
        else err = "internal";
        this.alertController.create({
          cssClass: 'system-alert',
          header: this.translate.instant('alert.saveFailure'),
          message: this.translate.instant('alert.'+err),
          buttons: ['OK']
        }).then((alert) => {alert.present()});

        console.error(err);
      });
    } else { // Catch invalid password
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.passNotMatch'),
        message: this.translate.instant('alert.passNotMatchMess'),
        buttons: ['OK']
      }).then((alert) => {alert.present()});
    }
  }
  /***/
/***/
}
