/******************************************************************************
*  @Filename: widget-light.component.ts
*  @Date: 26-01-2022
*  @Author: Adrien Lanco
*
*  Description: Widget LIGHT content variables and functions
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: updateState
    * Name: updateBrightness
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, Input, OnInit } from '@angular/core';
  /***/

  /* Services */
  import { WsService } from '../../../../../services/ws/ws.service';
  /***/
/***/

@Component({
  selector: 'app-widget-light',
  templateUrl: './widget-light.component.html',
  styleUrls: ['./widget-light.component.scss'],
})
export class WidgetLightComponent implements OnInit {

/* VARIABLES */
  @Input() public widget: any; // Widget data value

  constructor(private ws: WsService) { }
/***/

/* FUNCTIONS */
  ngOnInit() {
    this.widget.value = {};
    this.widget.value["OnOff"] = false;
    if (this.widget.data_id["Brightness"] && this.widget.data_id["Brightness"][0])
      this.widget.value["Brightness"] = 50;
  }

  /*
  * Name: updateState
  * Description: Update widget state
  *
  * Args:
  * - state (Boolean): Current state
  *
  * Return (Boolean): Return new state
  */
  public updateState(): void {
    let id;
    if (!this.widget["onLoad"])this.widget["onLoad"] = {};
    this.widget.onLoad["OnOff"] = !this.widget.value["OnOff"];

    if (this.widget.data_id["OnOff"][1] && this.widget.value["OnOff"] == false) {
      id = this.widget.data_id["OnOff"][1];
    } else {
      id = this.widget.data_id["OnOff"][0];
    }
    let toSend = {
        from: "ihm",
        to: this.widget.deviceType,
        mac: this.widget.mac,
        _id: id,
        value: !this.widget.value["OnOff"],
        cmd: 'set'
    }
    this.ws.send(toSend);
  }
  /***/

  /*
  * Name: updateBrightness
  * Description: Update widget state
  *
  * Args:
  * - state (Boolean): Current state
  *
  * Return (Boolean): Return new state
  */
  public updateBrightness(value): void {
    if (this.widget.value["Brightness"] != undefined && (value || value == 0) &&
        this.widget.value["Brightness"] != value) {
      this.widget.value["Brightness"] = value;
      if (!this.widget["onLoad"])this.widget["onLoad"] = {};
      this.widget.onLoad["Brightness"] = value;
      let toSend = {
          from: "ihm",
          to: this.widget.deviceType,
          mac: this.widget.mac,
          _id: this.widget.data_id["Brightness"][0],
          value: this.widget.value["Brightness"],
          cmd: 'set'
      }
      this.ws.send(toSend);
    }
  }
  /***/
/***/
}
