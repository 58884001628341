/******************************************************************************
*  @Filename: gce-range.component.ts
*  @Date: 01-02-2022
*  @Author: Adrien Lanco
*
*  Description: gce range component
*******************************************************************************/

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-gce-range',
  templateUrl: './gce-range.component.html',
  styleUrls: ['./gce-range.component.scss'],
})
export class GceRangeComponent implements OnInit {
  @Input() public startIcon: any; // Range start Icon
  @Input() public endIcon: any;   // Range end Icon

  @Input() public min: number; // Range min
  @Input() public max: number; // Range max

  @Input() public value: number; // Range max

  @Input() public disabled: boolean;   // Range end Icon

  @Output() public onDataChange = new EventEmitter<number>(); // Remove event

  constructor() { }

  ngOnInit() {
  }
}
