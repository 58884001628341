/******************************************************************************
*  @Filename: edit-device.component.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description: Edit device modal variables and functions
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Component
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: save
    * Name: invalidField
*/

/* IMPORT */
    /* Angular/Ionic */
  import { Component, OnInit, Input } from '@angular/core';
  import { ModalController, AlertController } from '@ionic/angular';
  import {TranslateService} from '@ngx-translate/core';
  /***/

  /* Services */
  import { ApiService } from '../../../services/api/api.service';
  /***/
/***/


@Component({
  selector: 'app-edit-device',
  templateUrl: './edit-device.component.html',
  styleUrls: ['./edit-device.component.scss'],
})
export class EditDeviceComponent implements OnInit {

/* VARIABLES */
  @Input() public device: any;
  @Input() public userId: string;

  constructor(private modalController: ModalController,
              private alertController: AlertController,
              private api: ApiService,
              private translate: TranslateService) {
  }
/***/

/* FUNCTIONS */
  ngOnInit() {
  }

  /*
  * Name: save
  * Description: Save device update
  *
  * Args:
  * - device (Oject): Device data
  */
  public save(device): void {
    this.api.request('PUT', '/api/users/'+this.userId+'/devices/'+device._id, device)
    .then((ret) => {
      this.modalController.dismiss(ret, 'saved'); // Return registered device
    }).catch((err) => {
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.devSaveErr'),
        message: this.translate.instant('alert.internal'),
        buttons: ['OK']
      }).then((alert) => {alert.present()});

      console.error(err);
    });
  }
  /***/

  /*
  * Name: invalidField
  * Description: check for field validity
  *
  * Return: true if the field is invalid
  */
  public invalidField(): boolean {
    let target = document.getElementById("gce-edit-device-config");
    if (target) {
      let invalid = target.getElementsByClassName("gce-invalid-field");
      if (invalid.length > 0) return true;
      else return false;
    } else return true;
  }
  /***/
/***/
}
