/******************************************************************************
*  @Filename: account.component.ts
*  @Date: 05/11/2021
*  @Author: Jbristhuille
*
*  Description: Account page variables and functions
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Component
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: getUser
    * Name: openEditModal
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit } from '@angular/core';
  import { AlertController, ModalController } from '@ionic/angular';
  import { Router, ActivatedRoute } from '@angular/router';
  import {TranslateService} from '@ngx-translate/core';
  /***/

  import { environment } from '../../../../environments/environment';

  /* Services */
  import { ApiService } from '../../../services/api/api.service';
  import { DataService } from '../../../services/data/data.service';
  import { SessionService } from '../../../services/session/session.service';
  /***/

  /* Component */
  import { UnlinkComponent } from '../../modals/unlink/unlink.component';
  import { EditUserComponent } from '../../modals/edit-user/edit-user.component';
  /***/
/***/

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {

  public version: string;
/* VARIABLES */
  private onLoad = true;


  constructor(private alertController: AlertController,
              private modalController: ModalController,
              private api: ApiService,
              private session: SessionService,
              private router: Router,
              private data: DataService,
              private translate: TranslateService) {
  }
/***/

/* FUNCTIONS */
  async ngOnInit() {
    await this.data.initData("user");
    this.version = environment['version'];
    this.onLoad = false;
  }

  /*
  * Name: openEditModal
  * Description: Open edition modal
  */
  public openEditModal(): void {
    this.modalController.create({
      component: EditUserComponent,
      cssClass: 'system-modal',
      componentProps: {
        'user': this.data.user,
        'onRemove': false,
        'editTheme': this.data.user["theme"] == "dark" ? true : false
      }
    }).then(async (modal) => {
      modal.present();

      modal.onWillDismiss().then((ret) => {
        if (ret.role == 'saved') { // Only after device registration
          this.data.user = ret.data;
        }
      });
    });
  }
  /***/

  /*
  * Name: openEditModal
  * Description: Open edition modal
  */
  public openRemoveModal(): void {
    this.modalController.create({
      component: EditUserComponent,
      cssClass: 'system-modal',
      componentProps: {
        'user': this.data.user,
        'onRemove': true
      }
    }).then(async (modal) => {
      modal.present();
    });
  }
  /***/

  /*
  * Name: openUnlinkModal
  * Description: Open Unlink modal
  *
  * Args:
  *  - type (String): googlehome or alexa
  */
  public openUnlinkModal(type): void {
    this.modalController.create({
      component: UnlinkComponent,
      cssClass: 'system-modal',
      componentProps: {
        'user': this.data.user,
        'type': type
      }
    }).then(async (modal) => {
      modal.present();
      modal.onWillDismiss().then((ret) => {
        if (ret.role == 'saved') { // Only after device registration
          this.data.user = ret.data;
        }
      });
    });
  }
  /***/
/***/
}
