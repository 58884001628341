/******************************************************************************/
/*  Name: services.module.ts                                                  */
/*  Date: 28/10/2021                                                          */
/*  Author: Jbristhuille                                                      */
/*                                                                            */
/*  Description: Global services                                              */
/******************************************************************************/

/* SUMMARY
  * Angular
*/

/* Angular */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/***/

/* Services */
import { SessionService } from './session/session.service';
import { ApiService } from './api/api.service';
import { ColorService } from './color/color.service';
/***/

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [
    SessionService,
    ApiService,
    ColorService
  ]
})
export class ServicesModule { }
