/******************************************************************************
*  @Filename: component.module.ts
*  @Date: 05-05-2022
*  @Author: Adrien Lanco
*
*  Description:
*******************************************************************************/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

import { StepProgressComponent } from './step-progress/step-progress.component';
import { ToggleComponent } from './toggle/toggle.component';
import { TooltipComponent } from './tooltip/tooltip.component';

@NgModule({
  declarations: [
    StepProgressComponent,
    ToggleComponent,
    TooltipComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    StepProgressComponent,
    ToggleComponent,
    TooltipComponent
  ]
})
export class ComponentModule { }
