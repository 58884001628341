/******************************************************************************
*  @Filename: environment.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description: Environment variables
*******************************************************************************/

export const environment = {
  production: true,
  api: 'https://smartgce.com:5000',
  ws: 'wss://smartgce.com:1000/ihm/open',
  api_dev: 'https://api-smart-gce.ingenosya.net',//'https://localhost:5000',
  ws_dev: 'wss://api-smart-gce.ingenosya.net:1000/ihm/open',
  version: "1.1.0"
};
