/******************************************************************************
 *  @Filename: unlink.component.ts
 *  @Date: 14-02-2022
 *  @Author: Adrien Lanco
 *
 *  Description: unlink modal variables and functions
 *******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: unlink
*/

/* IMPORT */
/* Angular/Ionic */
import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
/***/
/* Services */
import {ApiService} from '../../../services/api/api.service';
import {SessionService} from '../../../services/session/session.service';
/***/
/* Node modules */
import * as _ from 'lodash';
/***/

/***/

@Component({
  selector: 'app-unlink',
  templateUrl: './unlink.component.html',
  styleUrls: ['./unlink.component.scss'],
})
export class UnlinkComponent implements OnInit {

  /* VARIABLES */
  @Input() public user: any;
  @Input() public type: any;

  constructor(private alertController: AlertController,
              private modalController: ModalController,
              private api: ApiService,
              private session: SessionService,
              private translate: TranslateService) {
  }

  /***/

  /* FUNCTIONS */
  ngOnInit() {
  }

  /*
  * Name: unlink
  * Description: send request to unbind user from Google account
  *
  */
  private unlink() {
    let user = this.session.getUser();
    let data = _.cloneDeep(user);

    if (this.type == "Google") data.gShortLiveToken = null;
    if (this.type == "Alexa") data.aAlexaToken = null;

    this.api.request('PUT', '/api/users/' + this.user._id, data)
      .then((ret) => {
        this.session.update(ret);
        this.alertController.create({
          cssClass: 'system-alert',
          header: this.type,
          message: this.type + ' : ' + this.translate.instant('alert.accountUnlinked'),
          buttons: ['OK']
        }).then((alert) => {
          alert.present()
        });

        this.modalController.dismiss(ret, 'saved'); // Return registered device
      }).catch((err) => { // Catch API error
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.unlinkFail'),
        message: this.translate.instant('alert.internal'),
        buttons: ['OK']
      }).then((alert) => {
        alert.present()
      });
      this.modalController.dismiss(err, 'err'); // Return registered device
      console.error(err);
    });
  }

  /***/
  /***/
}
