/******************************************************************************
*  @Filename: toggle.component.ts
*  @Date: 05-07-2022
*  @Author: Adrien Lanco
*
*  Description:
*******************************************************************************/

import { Component, Input, Output, HostBinding, OnInit, EventEmitter } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {

  constructor(private tr: TranslateService) { }

  @Input() public title: string;
  @Input() public text: any;

  ngOnInit() {
    if (this.text && !Array.isArray(this.text)) {
      this.text = [this.text]
    }
  }
}
