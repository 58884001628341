/******************************************************************************
*  @Filename: widget.component.ts
*  @Date: 19/11/2021
*  @Author: Jbristhuille
*
*  Description: Widget variables and functions
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: openEditModal
    * Name: openRemoveModal
    * Name: isEmpty
    * Name: haveTraitError
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
  import { ModalController } from '@ionic/angular';
  /***/

  /* Components */
  import { EditWidgetComponent } from '../../modals/edit-widget/edit-widget.component';
  /***/

  /* Services */
  import { SessionService } from '../../../services/session/session.service';
  /***/

  /* Node modules */
  import * as _ from 'lodash';
  /***/
/***/

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements OnInit {

/* VARIABLES */
  @Input() public widget: any; // Widget data
  @Input() public editMode: boolean; // Widget data
  @Output() public edit = new EventEmitter(); // Remove event
  @Output() public remove = new EventEmitter(); // Remove event

  constructor(private modalController: ModalController,
              private session: SessionService) {
  }
/***/

/* FUNCTIONS */
  ngOnInit() {
    if (!this.widget.name) this.widget.name = '-'
  }

  /*
  * Name: openEditModal
  * Description: Open edit widget modal
  *
  * Args:
  * - widget (Object): Widget to edit
  */
  public openEditModal(widget): void {
    let user = this.session.getUser();

    this.modalController.create({
      component: EditWidgetComponent,
      cssClass: 'system-modal',
      componentProps: {
        'user_id': user._id,
        'widget': _.cloneDeep(widget),
        'isRemove': false
      }
    }).then(async (modal) => {
      modal.present();

      modal.onWillDismiss().then(async (ret) => {
        if (ret.role == 'saved') { // Only after device registration
          if (ret.data) {
            this.widget = _.merge(this.widget, ret.data)
            this.edit.emit();
          }
          else this.remove.emit();
        }
      });
    });
  }
  /***/

  /*
  * Name: openRemoveModal
  * Description: Open edit widget modal
  *
  * Args:
  * - widget (Object): Widget to edit
  */
  public openRemoveModal(widget): void {
    let user = this.session.getUser();

    this.modalController.create({
      component: EditWidgetComponent,
      cssClass: 'system-modal',
      componentProps: {
        'user_id': user._id,
        'widget': _.cloneDeep(widget),
        'isRemove': true
      }
    }).then(async (modal) => {
      modal.present();

      modal.onWillDismiss().then(async (ret) => {
        if (ret.role == 'saved') { // Only after device registration
          if (ret.data) this.widget = _.merge(this.widget, ret.data);
          else this.remove.emit();
        }
      });
    });
  }
  /***/

  /*
  * Name: isEmpty
  * Description: check if the object is empty
  *
  * Args:
  * - obj (Object): object to check
  */
  public isEmpty(obj): void {
    return _.isEmpty(obj)
  }
  /***/

  /*
  * Name: haveTraitError
  * Description: check if the object is empty
  *
  * Args:
  * - obj (Object): object to check
  */
  public haveTraitError(): boolean {
    if (this.widget['errTrait']) {
      for (const [key, value] of Object.entries(this.widget.errTrait)) {
        if (value === true) return true;
      }
    }
    return false;
  }
  /***/
/***/
}
