/******************************************************************************
*  @Filename: dashboard.module.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description:
*******************************************************************************/

/******************************************************************************/
/*  Name: dashboard.module.ts                                                 */
/*  Date: 28/10/2021                                                          */
/*  Author: Jbristhuille                                                      */
/*                                                                            */
/*  Description: Manage dashboard view                                        */
/******************************************************************************/

/* SUMMARY
  * Angular
  * Components
*/

/* Angular */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { ComponentModule } from '../components/component.module';
/***/

/* Components */
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { WidgetComponent } from './components/widget/widget.component';

import { StepProgressComponent } from '../components/step-progress/step-progress.component';
import { ToggleComponent } from '../components/toggle/toggle.component';
import { TooltipComponent } from '../components/tooltip/tooltip.component';

  /* Widget Type */
  import { WidgetOutletComponent } from './components/widget/content/widget-outlet/widget-outlet.component';
  import { WidgetLightComponent } from './components/widget/content/widget-light/widget-light.component';
  import { WidgetShutterComponent } from './components/widget/content/widget-shutter/widget-shutter.component';

  /***/

  /* Widget Components */
  import { GceRangeComponent } from './components/widget/components/gce-range/gce-range.component';
  /***/

  /* Modal */
  import { AddWidgetComponent } from './modals/add-widget/add-widget.component';
  import { EditWidgetComponent } from './modals/edit-widget/edit-widget.component';
    /* Modal Components */
    import { V4DataSelectorComponent } from "./modals/components/widgetV4/v4-data-selector/v4-data-selector.component";
    import { V5DataSelectorComponent } from "./modals/components/widgetV5/v5-data-selector/v5-data-selector.component";
    /***/
  /***/
/***/
import { SortablejsModule } from 'ngx-sortablejs';

@NgModule({
  declarations: [
    DashboardComponent,
    WidgetComponent,
    WidgetOutletComponent,
    WidgetLightComponent,
    WidgetShutterComponent,
    AddWidgetComponent,
    EditWidgetComponent,
    V4DataSelectorComponent,
    V5DataSelectorComponent,
    GceRangeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SortablejsModule,
    TranslateModule,
    ComponentModule
  ],
  providers: [
    DashboardComponent
  ],
  exports: [DashboardComponent]
})
export class DashboardModule {}
