/******************************************************************************
*  @Filename: edit-user.component.ts
*  @Date: 05/11/2021
*  @Author: Jbristhuille
*
*  Description: Edit user modal variables and functions
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Node modules
    * Name: save
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit, Input } from '@angular/core';
  import { AlertController, ModalController } from '@ionic/angular';
  import { TranslateService } from '@ngx-translate/core';
  /***/

  /* Services */
  import { ApiService } from '../../../services/api/api.service';
  import { SessionService } from '../../../services/session/session.service';
  import { ColorService } from '../../../services/color/color.service';
  /***/

  /* Node modules */
  import * as _ from 'lodash';
  /***/
/***/

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {

/* VARIABLES */
  @Input() public user: any;
  @Input() public onRemove: boolean;
  @Input() public editTheme: boolean;

  public editPasswd: boolean = false;
  public editLang: string;

  constructor(private alertController: AlertController,
              private modalController: ModalController,
              private api: ApiService,
              private session: SessionService,
              private color: ColorService,
              private translate: TranslateService) {
  }
/***/

/* FUNCTIONS */
  ngOnInit() {
    this.editLang = this.user["lang"] || "en";
    this.editTheme = this.user["theme"] == "dark" ? true : false;
  }

  /*
  * Name: save
  * Description: Save user update
  *
  * Args:
  * - user (Object): User data
  * - passwd (String): New password string
  * - confirmPass (String): Confirm password string
  */
  save (passwd, confirmPasswd): void {
    if (!this.editPasswd || (passwd && confirmPasswd && passwd == confirmPasswd)) {
      let user = this.session.getUser();
      let data = _.cloneDeep(user);
      if (this.editPasswd) data['password'] = String(passwd);
      data['theme'] = this.editTheme ? "dark" : "light";
      this.user["theme"] = this.editTheme ? "dark" : "light";
      this.user["lang"] = this.editLang;
      data['lang'] = this.editLang;

      this.api.request('PUT', '/api/users/'+user._id, data)
      .then((ret) => {
        this.session.update(this.user)
        .then(() => {
          this.alertController.create({
            cssClass: 'system-alert',
            header: this.translate.instant('alert.userSaved'),
            message: this.translate.instant('alert.userSavedMess')+(this.editPasswd ? this.translate.instant('alert.userSavedPass') : ''),
            buttons: ['OK']
          }).then((alert) => {alert.present()});
          this.color.setTheme(ret.theme || "light");
          this.modalController.dismiss(ret, 'saved'); // Return registered device
        });
      }).catch((err) => { // Catch API error
        if (err == "User not found" ) err = "userNotFound";
        else err = "internal";
        this.alertController.create({
          cssClass: 'system-alert',
          header: this.translate.instant('alert.saveFailure'),
          message: this.translate.instant('alert.'+err),
          buttons: ['OK']
        }).then((alert) => {alert.present()});

        console.error(err);
      });
    } else { // Catch invalid password
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.passNotMatch'),
        message: this.translate.instant('alert.passNotMatchMess'),
        buttons: ['OK']
      }).then((alert) => {alert.present()});
    }
  }
  /***/


  /*
  * Name: deleteUser
  * Description: delete user data
  *
  * Return (Object): delete data
  */
  private deleteUser(): Promise<any[]> {
    return new Promise((resolve) => {
      let user = this.session.getUser();
      this.api.request('DELETE', '/api/users/'+user._id, null)
      .then((ret) => {
        this.session.disconnect(true);
        this.alertController.create({
          cssClass: 'system-alert',
          header: this.translate.instant('alert.removeSucces'),
          message: this.translate.instant('alert.removeSuccesStart')+user.login+this.translate.instant('alert.removeSuccesEnd'),
          buttons: ['OK']
        }).then((alert) => {
          alert.present();
          this.modalController.dismiss({}, 'deleted');
        });
        return resolve(null);
      }).catch((err) => {
        this.alertController.create({
          cssClass: 'system-alert',
          header: this.translate.instant('alert.userDelErr'),
          message: this.translate.instant('alert.internal'),
          buttons: ['OK']
        }).then((alert) => {
          alert.present()
          this.modalController.dismiss();
        });

        console.error(err);
        return resolve(null);
      });
    });
  }
  /***/

  /*
  * Name: changeTheme
  * Description: change theme color
  *
  */
  private changeTheme(evt): void {
    this.editTheme = !this.editTheme;
    this.color.changeTheme(evt);
  }
  /***/
/***/
}
