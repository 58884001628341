/******************************************************************************
*  @Filename: add-widget.component.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description: Add widget modal variables and styles
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Component
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: addWidget
    * Name: onDeviceSelected
    * Name: onTypeSelected
    * Name: isInvalidStep
    * Name: isInvalid
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit } from '@angular/core';
  import { AlertController, ModalController } from '@ionic/angular';
  import {TranslateService} from '@ngx-translate/core';
  /***/

  /* Component */
  import {V4DataSelectorComponent} from "../components/widgetV4/v4-data-selector/v4-data-selector.component";
  import {V5DataSelectorComponent} from "../components/widgetV5/v5-data-selector/v5-data-selector.component";
  /***/

  /* Services */
  import { SessionService } from '../../../services/session/session.service';
  import { ApiService } from '../../../services/api/api.service';
  import { CmdService } from '../../../services/cmd/cmd.service';
  import { DataService } from '../../../services/data/data.service';

  import { ModalService } from '../services/modal/modal.service';
  /***/
/***/

@Component({
  selector: 'app-add-widget',
  templateUrl: './add-widget.component.html',
  styleUrls: ['./add-widget.component.scss'],
})
export class AddWidgetComponent implements OnInit {

/* VARIABLES */
  public widget: any = {
    type: '', // Widget type
    traits: {},
    name: '', // Widget name
    data_id: {}
  };
  public devices: any;
  public typeList: any;
  public actualTraits: any;

  public openIds: boolean = false;
  public onGetIdError: string = null;

  public actualStep: number = 1;
  public nbStep:number = 5;

  public v4SingleBlind: boolean = false;

  constructor(private alertController: AlertController,
              private modalController: ModalController,
              private session: SessionService,
              private api: ApiService,
              private cmd: CmdService,
              private data: DataService,
              private translate: TranslateService,
              public modalService: ModalService) {
  }
/***/

/* FUNCTIONS */
  ngOnInit() {
    this.data.getUserDevices()
    .then((ret) => {
      this.devices = ret; // Return registered device

    }).catch((err) => {
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.devicesLoadingErr'),
        message: this.translate.instant('alert.internal'),
        buttons: ['OK']
      }).then((alert) => {alert.present()});

      console.error(err);
    });
  }

  /*
  * Name: addWidget
  * Description: Add widget
  *
  * Args:
  * - data (Object): Widget data
  */
  public addWidget(data): void {
    let user = this.session.getUser();

    data.mac = data.device.mac;
    data.deviceType = data.device.type;
    data.googlehome = this.cmd.genGoogleDevice(data, user._id);

    delete data.device;
    delete data.traits;

    this.api.request('POST', '/api/users/'+user._id+'/widgets', data)
    .then((ret) => {
      this.modalController.dismiss(ret, 'saved'); // Return registered device
    }).catch((err) => {
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.widgetSaveErr'),
        message: this.translate.instant('alert.internal'),
        buttons: ['OK']
      }).then((alert) => {alert.present()});
      console.error(err);
    });
  }
  /***/

  /*
  * Name: onDeviceSelected
  * Description: set the widgets type list in the modal
  *              from the type of device selected
  *
  */
  public onDeviceSelected(device) {
    this.widget.device = device;
    this.widget.type = null;
    if (device.type == "ipx800v4") {
      this.typeList = [
        { type: "OUTLET",   name: "Outlet" },
        { type: "LIGHT",    name: "Light" },
        { type: "BLINDS",  name: "Blinds" },
      ]
    } else if (device.type == "ipx800v5") {
      this.typeList = [
        { type: "OUTLET",   name: "Outlet" },
        { type: "LIGHT",    name: "Light" },
        { type: "BLINDS",  name: "Blinds" },
      ]
    }
  }
  /***/

  /*
  * Name: onTypeSelected
  * Description: set the data to configure in the modal
  *              for the selected widget
  *
  */
  public onTypeSelected(type) {
    if (type) {
      this.onGetIdError = null;
      this.widget.data_id = {};
      this.widget.traits = {};
      this.widget.type = type;
      let traits = this.cmd.getTraitsFromType(this.widget.device.type, this.widget.type);
      for (let i = 0; i < traits.length; i++) {
        this.widget.data_id[traits[i].name] = [];
        this.widget.traits[traits[i].name] = traits[i].required;
        for (let j = 0; j < traits[i].data.length; j++) {
          this.widget.data_id[traits[i].name][j] = "";
        }
      }
      this.actualTraits = traits;
    }
  }
  /***/

  /*
  * Name: isInvalidStep
  * Description: check if the modal step is complete
  *
  * Return: true if the step is not complete
  */
  public isInvalidStep(): boolean {
    let ret;

    switch (this.actualStep) {
      case 1: ret = this.widget.name ? false : true; break;
      case 2: ret = this.widget.cmd ? false : true; break;
      case 3: ret = this.widget.device ? false : true; break;
      case 4: ret = this.widget.type ? false : true; break;

      case 5:
        ret = false;
        let traits = this.cmd.getTraitsFromType(this.widget.device.type, this.widget.type);
        for (let i = 0; i < traits.length; i++) {
          if (this.widget.traits[traits[i].name] == true) {
            if (!this.widget.data_id[traits[i].name][0] ||
                this.widget.data_id[traits[i].name][0] == "" )
              ret = true;
              break;
          }
        };
      break;
    }
    return ret;
  }
  /***/

  /*
  * Name: isInvalid
  * Description: check if the widget is not in error
  *
  * Return: true if the widget is invalid
  */
  public isInvalid(): boolean {
    if (!this.widget.name ||
        !this.widget.cmd ||
        !this.widget.device ||
        !this.widget.type) {
          return true
    }
    let traits = this.cmd.getTraitsFromType(this.widget.device.type, this.widget.type);
    for (let i = 0; i < traits.length; i++) {
      if (this.widget.traits[traits[i].name] == true) {
        if (!this.widget.data_id[traits[i].name][0] ||
            this.widget.data_id[traits[i].name][0] == "" )
          return true
      }
    }
    return false;
  }
  /***/

  /*
  * Name: getV5Ids
  * Description: get ids from v5
  *              from the output of the v5 data selector
  */
  public getV5Ids(event) {
    this.onGetIdError = null;
    this.widget.objSource = event;
    this.modalService.getV5Ids(this.widget, event)
    .catch((err) => {
      for (let i = 0; i < this.actualTraits.length; i++) {
        if (this.actualTraits[i].required && this.widget.traits[this.actualTraits[i].name]) {
          this.widget.data_id[this.actualTraits[i].name] = [null];
        }
      }
      if (err == "Timeout")
        this.onGetIdError = "GetIdsTimeout";
      else
        this.onGetIdError = "GetIdsNotFound";
    })
  }
  /***/

  public onV4SingleBlindChange(): void {
    this.v4SingleBlind = !this.v4SingleBlind;
    if (this.v4SingleBlind == true) {
      this.widget.data_id["BLINDS"][1] = null
    }
  }
/***/
}
