/******************************************************************************
*  @Filename: cmd.service.ts
*  @Date: 18-01-2022
*  @Author: Adrien Lanco
*
*  Description: used to construct google home device from widget
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Component
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: getGoogleDeviceTraits
    * Name: getV4TraitsFromType
    * Name: getV5TraitsFromType
    * Name: genGoogleDevice
    * Name: getGoogleType
    * Name: getGoogleDeviceTraits
    * Name: getGoogleDeviceTraitsNames
    * Name: getGoogleDeviceDefaultNames
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Injectable } from '@angular/core';
  /***/

  /* Node modules */
  import * as _ from 'lodash';
  /***/
/***/

@Injectable({
  providedIn: 'root'
})
export class CmdService {

  constructor() { }

/* FUNCTIONS */
  /*
  * Name: getGoogleDeviceTraits
  *
  */
  public getTraitsFromType(deviceType, type): any {
    let ret;

    if (deviceType == "ipx800v4") {
      ret = this.getV4TraitsFromType(type);
    }
    if (deviceType == "ipx800v5") {
      ret = this.getV5TraitsFromType(type);
    }
    return ret;
  }
  /***/

  /*
  * Name: getV4TraitsFromType
  *
  */
  public getV4TraitsFromType(type): any {
    let ret = [];
    switch (type) {
      case 'IO':
        ret.push({name: "OnOff",
                  required: "true",
                  data: [
                    { name:"OnOff", type:"io" }
                  ]});
      break;

      case 'LIGHT':
        ret.push({name: "OnOff",
                  required: "true",
                  data: [
                    { name:"OnOff", type:"io" }
                  ]});
      break;

      case 'OUTLET':
        ret.push({name: "OnOff",
                  required: "true",
                  data: [
                    { name:"OnOff", type:"io" }
                  ]});
      break;

      case 'BLINDS':
        ret.push({name: "OpenClose",
                  required: "true",
                  googleAttributes: {
                    "discreteOnlyOpenClose": true,
                    "commandOnlyOpenClose": true
                  },
                  data: [
                    { name:"Up",   type:"io" },
                    { name:"Down", type:"io" }
                  ]});
        ret.push({name: "StartStop",
                  required: "true",
                  data: [
                    { name:"Stop", type:"io" }
                  ]});
      break;
    }
    return ret;
  }
  /***/

  /*
  * Name: getV5TraitsFromType
  *
  */
  public getV5TraitsFromType(type): any {
    let ret = [];
    switch (type) {
      case 'IO':
        ret.push({name: "OnOff",
                  required: "true",
                  data: [
                    { name:"OnOff", type:"io" }
                  ]});
      break;

      case 'LIGHT':
        ret.push({name: "OnOff",
                  required: true,
                  data: [
                    { name:"OnOff",      type:"io" }
                  ]});
        ret.push({name: "Brightness",
                  required: false,
                  data: [
                    { name:"Brightness", type: "ana" }
                  ]});
      break;

      case 'OUTLET':
        ret.push({name: "OnOff",
                  required: true,
                  data: [
                    { name:"OnOff",     type:"io" }
                  ]});
      break;

      case 'BLINDS':
        ret.push({name: "OpenClose",
                  required: true,
                  data: [
                    // { name:"Up",   type:"io" },
                    // { name:"Down", type:"io" }
                    { name:"Command",  type:"ana" }
                  ]});
        ret.push({name: "Position",
                  required: true,
                  notGoogle: true,
                  data: [
                    {name:"Position",       type:"ana" }
                ]});
        ret.push({name: "StartStop",
                  required: true,
                  data: [
                    {name:"Stop",       type:"io" }
                ]});
      break;
    }
    return ret;
  }
  /***/

  /*
  * Name: genGoogleDevice
  *
  */
  public genGoogleDevice(data, user_id): any {
    let traits = [];
    let attributes = {};
    if (data.device.type == "ipx800v4") {
      traits = this.getV4TraitsFromType(data.type);
    } else if (data.device.type == "ipx800v5") {
      traits = this.getV5TraitsFromType(data.type);
    }

    let activeTraits = [];
    let ids = _.cloneDeep(data.data_id);
    let j = 0;
    for (let i = 0; i < traits.length; i++) {
      if (data.traits[traits[i].name] && !traits[i].notGoogle) {
        if (traits[i].googleAttributes) attributes = _.merge(traits[i].googleAttributes, attributes);
        activeTraits.push("action.devices.traits."+traits[i].name);
      }
    }

    let type = this.getGoogleType(data.type);
    let id = data._id ? data._id : "";
    let ret = {
      "id": id,
      "type": "action.devices.types."+type,
      "traits": activeTraits,
      "name": {
        "defaultNames": this.getGoogleDeviceDefaultNames(data.type),
        "name": data.name,
        "nicknames": [ data.cmd ]
      },
      "willReportState": true,
      "deviceInfo": {
        "manufacturer": "GCE-Electronics",
        "model": data.type
      },
      "customData": {
        "_id": ids,
        "from": "googlehome",
        "to": data.deviceType,
        "mac": data.mac,
        "user": user_id
      }
    }

    if (attributes && Object.keys(attributes).length !== 0) {
      ret["attributes"] = attributes;
    }
    return ret;
  }
  /***/

  /*
  * Name: getGoogleType
  *
  */
  public getGoogleType(type): any {
    let ret;
    switch (type) {
      case 'IO':     ret = "OUTLET";  break;

      case 'ANA':    ret = "LIGHT";   break;

      default: ret = type; break;
    }
    return ret;
  }
  /***/

  /*
  * Name: getGoogleDeviceTraits
  *
  */
  public getGoogleDeviceTraits(type): any {
    let ret = [];
    switch (type) {
      case 'LIGHT':     ret.push("action.devices.traits.OnOff");
                        ret.push("action.devices.traits.Brightness");      break;

      case 'OUTLET':    ret.push("action.devices.traits.OnOff");           break;

      case 'BLINDS':    ret.push("action.devices.traits.OpenClose");
                        ret.push("action.devices.traits.StartStop");        break;
    }
    return ret;
  }
  /***/

  /*
  * Name: getGoogleDeviceTraitsNames
  *
  */
  public getGoogleDeviceTraitsNames(type): any {
    let traits = this.getGoogleDeviceTraits(type);
    let ret = [];
    for (let i = 0; i < traits.length; i++)
      ret[i] = traits[i].substring(22);

    return ret;
  }
  /***/

  /*
  * Name: getGoogleDeviceDefaultNames
  *
  */
  private getGoogleDeviceDefaultNames(type): any {
    let ret = [];
    switch (type) {
      case 'LIGHT':   ret.push("light");            break;
      case 'OUTLET':  ret.push("wall plug");        break;
      case 'BLINDS':  ret.push("roller shutter");   break;
    }
    return ret;
  }
  /***/
}
