/******************************************************************************/
/*  Name: ...                                                                 */
/*  Date: ...                                                                 */
/*  Author: ...                                                               */
/*                                                                            */
/*  Description: ...                                                          */
/******************************************************************************/

/* SUMMARY
  * Angular
*/

/* Angular */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
/***/

/* Modules */
import { ServicesModule } from '../services/services.module';
/***/

/* Component */
import { LoginComponent } from './components/login/login.component';
import { SigninComponent } from './components/signin/signin.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import {AlexaLoginComponent} from "./components/alexa-login/alexa-login.component";
/***/

@NgModule({
  declarations: [
    LoginComponent,
    AlexaLoginComponent,
    SigninComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ServicesModule,
    TranslateModule
  ],
  exports: [LoginComponent]
})
export class LoginModule {
}
