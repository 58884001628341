/******************************************************************************
*  @Filename: session.service.ts
*  @Date: 28/10/2021
*  @Author: Jbristhuille
*
*  Description: Manage user session
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: getUser
    * Name: isConnected
    * Name: connect
    * Name: unauthorized
    * Name: disconnect
    * Name: getToken
    * Name: update
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Injectable } from '@angular/core';
  import { Router } from '@angular/router';
  import { AlertController } from '@ionic/angular';
  import {TranslateService} from '@ngx-translate/core';
  /***/

  /* Services */
  import { ColorService } from '../color/color.service';
  /***/

  /* Node modules */
  import * as _ from 'lodash';
  /***/
/***/

@Injectable({
  providedIn: 'root'
})
export class SessionService {

/* VARIABLES */
  public connected: boolean = false;
  private user: any;

  public api: any;
  public wsClose: any;

  constructor(private alertController: AlertController,
              private router: Router,
              private color: ColorService,
              private translate: TranslateService) {
  }
/***/

/* FUNCTIONS */

  /*
  * Name: getUser
  * Description: Return connected user
  *
  * Return (Object): Connected user or null
  */
  public getUser(): any {
    return this.user || null;
  }
  /***/

  /*
  * Name: isConnected
  * Description: Return connection state
  *
  * Return (Boolean): Session is active or not
  */
  public isConnected(): boolean {
    return this.connected;
  }
  /***/

  /*
  * Name: connect
  * Description: Connect user, active session
  *
  * Args:
  * - user (Object | Null): User to connect or null for local storage check
  */
  public connect(user): boolean {
    try {
      if (!user) {
        let tmp = localStorage.getItem('user');
        if (tmp) user = JSON.parse(tmp);
      }

      if (user) {
        this.color.setTheme(user.theme || "light");
        this.translate.use(user.lang || "en");
        this.connected = true;
        this.user = user;
        localStorage.setItem('user', JSON.stringify(user));
        return true;
      } else return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
  /***/

  /*
  * Name: unauthorized
  * Description: Handle unauthorized action from users
  *              then disconect him
  */
  public unauthorized(): void {
    if (this.connected)
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.disconnected'),
        message: this.translate.instant('alert.disconnectedMess'),
        buttons: ['OK']
      }).then((alert) => {alert.present()});
    this.disconnect(false);
  }
  /***/

  /*
  * Name: disconnect
  * Description: Disconnect user
  *
  * Args:
  * - soft (Boolean): Solf disconnection option, send disconnection request to server
  */
  public disconnect(soft): void {
    if (soft) {
      this.api.request('POST', '/api/users/'+this.user._id+'/disconnect', {})
      .catch((err) => {
        console.error(err);
      });
    }
    this.wsClose()
    this.connected = false;
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
  }
  /***/

  /*
  * Name: getToken
  * Description: Get connected user token
  *
  * Return (String): User token
  */
  public getToken(): string {
    return this.user ? this.user.token : '';
  }
  /***/

  /*
  * Name: update
  * Description: Update session data
  *
  * Args:
  * - user (Object): Data to update
  */
  public async update(user): Promise<void> {
    this.user = _.merge(this.user, user);
    await this.translate.use(user.lang || "en");
    this.connect(this.user);
  }
  /***/
/***/
}
