/******************************************************************************
*  @Filename: app-routing.module.ts
*  @Date: 28/10/2021
*  @Author: Jbristhuille
*
*  Description: Routing
*******************************************************************************/

/* SUMMARY
  * Angular/Ionic
  * Modules
  * Components
  * Guards
  * Routes
*/

/* Angular/Ionic */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
/***/

/* Modules */
import { LoginModule } from './login/login.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DevicesModule } from './devices/devices.module';
import { AccountModule } from './account/account.module';
import { ThirdpartyModule } from './thirdparty/thirdparty.module';
/***/

/* Components */
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AdminComponent } from './admin/admin.component';

import { PrivacyPolicyComponent } from './policy/privacy-policy/privacy-policy.component';
import { LoginComponent } from './login/components/login/login.component';
import { SigninComponent } from './login/components/signin/signin.component';
import { ResetPasswordComponent } from './login/components/reset-password/reset-password.component';
import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';
import { DevicesComponent } from './devices/components/devices/devices.component';
import { AccountComponent } from './account/components/account/account.component';
import { LinkComponent } from './thirdparty/link/link.component';
/***/

/* Guards */
import { IsConnectedGuard } from './guards/is-connected/is-connected.guard';
import {AlexaLoginComponent} from './login/components/alexa-login/alexa-login.component';
/***/


/* Routes */
const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [IsConnectedGuard] },
  { path: 'alexa', component: AlexaLoginComponent, canActivate: [IsConnectedGuard] },
  { path: 'signin', component: SigninComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [IsConnectedGuard] },
  { path: 'devices', component: DevicesComponent, canActivate: [IsConnectedGuard] },
  { path: 'account', component: AccountComponent, canActivate: [IsConnectedGuard] },
  { path: 'thirdparty/link', component: LinkComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'admin', component: AdminComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent, canActivate: [IsConnectedGuard] }
];
/***/

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    LoginModule,
    DashboardModule,
    DevicesModule,
    AccountModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
