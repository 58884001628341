/******************************************************************************
*  @Filename: modal.service.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description: Manage modal function
*******************************************************************************/

/* SUMMARY
* IMPORT
  * Angular/Ionic
  * Services
  * Component
  * Node modules
* VARIABLES
* FUNCTIONS
  * Name: request
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Injectable } from '@angular/core';
  /***/

  /* Services */
  import { WsService } from '../../../../services/ws/ws.service';
  /***/
/***/

@Injectable({
  providedIn: 'root'
})
export class ModalService {
/* VARIABLES */
  constructor(private ws: WsService) {}
/***/

/* FUNCTIONS */

  /*
  * Name: getV5Ids
  * Description: get ids of data source from objInfo
  *
  * Args:
  * - widget (String): the widget the is edited
  * - objInfo (String): the string of the Object data source
  *
  */
  public async getV5Ids(widget, objInfo): Promise<void> {
    let update = {
      from: "ihm",
      to: "ipx800v5",
      mac: widget.device.mac,
      _id: widget.device._id,
      value: objInfo,
      cmd: 'GETIDs'
    }
    return new Promise((resolve, reject) => {
      this.ws.getV5Ids(widget, update)
      .then((ids) => {
        switch (widget.type) {
          case "OUTLET":
            widget.data_id['OnOff'] = [ids[0]];
            break;
          case "LIGHT":
            widget.data_id['OnOff'] = [ids[0]];
            break;
          case "BLINDS":
            widget.data_id['OpenClose'] = [ids[0]];
            widget.data_id['Position']  = [ids[1]];
            widget.data_id['StartStop'] = [ids[2]];
            break;
        }
        return resolve();
      }).catch((err) => {
        return reject(err);
      })
    })
  }
  /***/
/***/
}
