/******************************************************************************
*  @Filename: signin.component.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description: SignIn page variables and functions
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Component
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: register
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit } from '@angular/core';
  import { Router } from '@angular/router';
  import { AlertController } from '@ionic/angular';
  import {TranslateService} from '@ngx-translate/core';
  /***/

  /* Services */
  import { ApiService } from '../../../services/api/api.service';
  /***/
/***/

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {

/* VARIABLES */
  public email: String;
  public passwd: String;
  public confirm: String;

  public onLoad: boolean;

  constructor(private router: Router,
              private alertController: AlertController,
              private api: ApiService,
              private translate: TranslateService) {
  }
/***/

/* FUNCTIONS */
  ngOnInit() {}

  /*
  * Name: register
  * Description: Register account
  *
  * Args:
  * - email (String): Account email
  * - passwd (String): Account password
  * - confirm (String): Password confirmation
  */
  public register(email, passwd, confirm): void {
    if (email && passwd && confirm && passwd == confirm) {
      email = email.toLowerCase();
      this.onLoad = true;

      this.api.request('POST', '/api/users', {login: email, passwd: passwd})
      .then((ret) => {
        this.alertController.create({
          cssClass: 'system-alert',
          header: this.translate.instant('alert.accountCreated'),
          message: this.translate.instant('alert.accountCrStart')+ret.login+this.translate.instant('alert.accountCrEnd'),
          buttons: ['OK']
        }).then((alert) => {alert.present()});

        this.router.navigate(['/login']);
      }).catch((err) => {
        if (err == "Email already exist") err = "emailAlrExist";
        else if (err == "Login must be an email") err = "logMailErr";
        else err = "internal";
        this.alertController.create({
          cssClass: 'system-alert',
          header: this.translate.instant('alert.invalidField'),
          message: this.translate.instant('alert.'+err),
          buttons: ['OK']
        }).then((alert) => {alert.present()});
      }).then(() => {
        this.onLoad = false;
      });
    } else {
      let err;
      if (passwd != confirm) err = "passNotMatch";
      else err = "invalidFieldMess"

      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.invalidField'),
        message: this.translate.instant('alert.'+err),
        buttons: ['OK']
      }).then((alert) => {alert.present()});

      console.error('Field missing or invalid');
    }
  }
  /***/
/***/
}
