/******************************************************************************
*  @Filename: admin.component.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description: admin page variables and function
*******************************************************************************/

/* SUMMARY
* IMPORT
  * Angular/Ionic
  * Services
  * Component
  * Node modules
* VARIABLES
* FUNCTIONS
  * Name: connect
  * Name: openForgetPassModal
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit } from '@angular/core';
  import { CommonModule } from '@angular/common';
  import { FormsModule } from '@angular/forms'

  import { Router } from '@angular/router';
  import {TranslateService} from '@ngx-translate/core';
  import { AlertController, ModalController } from '@ionic/angular';
  /***/

  /* Services */
  import { ApiService } from '../services/api/api.service';
  /***/

  /* Component */

  /***/
/***/

@Component({
  selector: 'app-login',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {

/* VARIABLES */
  public categ : string;
  public user: any = {
    email: '',
    passwd: ''
  };

  public onLoad: boolean = false;

  public data: any = {
    cpu: "-",
    mem: "-",
    nbV5: "-",
    fluxV5: "-",
    nbV4: "-"
  };

  public usersList: any;
  public filter: string = "";
  public maxDevices: number;
  public selectedUser: any;
  public mess: string;

  public errMess: string;
  public v5Errors: any = [];
  public errFilter: string = "";
  public selectedErr: any;

  public grFilter: string = "";
  public selectedGr: any;

  public actualDescr: string = ""
  public editMode: boolean = false;

  public modal: any = {};

  public connected: boolean = false;
  public token: string;

  constructor(private router: Router,
              private api: ApiService,
              private alertController: AlertController,
              private modalController: ModalController,
              private translate: TranslateService) {
  }
/***/

/* FUNCTIONS */
  ngOnInit() {
  }

  /*
  * Name: connect
  * Description: Connect user
  *
  * Args:
  * - email (String): User email
  * - passwd (String): User password
  */
  public connect(email, passwd): void {
    this.onLoad = true;
    email = email.toLowerCase()
    let user = {login: email, passwd: passwd};

    this.api.request('POST', '/api/admin/connect', user)
    .then(async (ret) => {
      if (ret.token) {
        this.token = ret.token;
        this.connected = true;
        this.setCateg("info");
      }
    }).catch((err) => {
      if (err == 'Bad login') err = "userNotFound";
      else if (err = "Password not match") err = "passNotMatch";
      else err = "internal"
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.loginErr'),
        message: this.translate.instant('alert.'+err),
        buttons: ['OK']
      }).then((alert) => {alert.present()});
    }).then(() => {
      this.onLoad = false;
    });
  }
  /***/


  /*
  * Name: setCateg
  * Description: set admin categori to display from cat
  */
  public setCateg(cat): void {
    this.categ = cat;
    switch (cat) {
      case "info": this.getAdminData(); break;
      case "maxdevices": this.getUsersList(); break;
      case "errV5": this.getV5Errors(); break;
    }
  }
  /***/

  /*
  * Name: getAdminData
  * Description: get info data from server
  */
  public getAdminData(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.token) {
        this.api.request('GET', '/api/admin/data/'+this.token, null)
        .then(async (ret) => {
          this.data = ret;
          return resolve()
        }).catch((err) => {
          if (err == 'Forbiden') {
            this.connected = false;
            this.token = null;
          }
          return reject(err)
        })
      } else {
        this.connected = false;
        return reject()
      }
    })
  }
  /***/

  /*
  * Name: getUsersList
  * Description: get all users registered on the server in a list
  */
  public getUsersList(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.token) {
        this.api.request('GET', '/api/admin/usersList/'+this.token, null)
        .then(async (ret) => {
          this.usersList = ret;
          return resolve()
        }).catch((err) => {
          if (err == 'Forbiden') {
            this.connected = false;
            this.token = null;
          }
          return reject(err)
        })
      } else {
        this.connected = false;
        return reject()
      }
    })
  }
  /***/

  /*
  * Name: setMaxDevices
  * Description: change max devices value for current user
  */
  public setMaxDevices(index): void {
    this.selectedUser = this.usersList[index];
    this.selectedUser.index = index;
    this.maxDevices = this.selectedUser.maxDevices
  }
  /***/

  /*
  * Name: sendMaxDevice
  * Description: send new maxDevices value for the selected user to the server
  */
  public sendMaxDevice(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.token) {
        this.mess = "";
        if (!this.selectedUser) {
          this.mess = "noUserSelected";
          return;
        }
        if (this.maxDevices < 8) {
          this.mess = "minMaxIsHeigt";
          return;
        }
        let name = this.selectedUser.login;
        this.api.request('PUT', '/api/admin/users/'+this.selectedUser._id+"/"+this.token, { maxDevices: this.maxDevices})
        .then(async (ret) => {
          this.usersList[this.selectedUser.index].maxDevices = ret
          this.mess = "maxDevicesUpdated";
          return resolve()
        }).catch((err) => {
          if (err == 'Forbiden') {
            this.connected = false;
            this.token = null;
          }
          this.mess = "maxDevicesError";
          return reject(err)
        })
      } else {
        this.connected = false;
        return reject()
      }
    })
  }
  /***/

  /*
  * Name: setV5Err
  * Description: select one of the v5 errors
  */
  public setV5Err(index): void {
    this.selectedErr = this.v5Errors[index];
    this.selectedGr = null;
    this.errMess = "";
    if (this.isGenError() && this.selectedErr['desc'] && this.selectedErr['desc'][0]) {
      this.actualDescr = this.selectedErr.desc[0].desc;
    } else {
      this.actualDescr = ""
    }
  }
  /***/

  /*
  * Name: setV5Gr
  * Description: select one of the v5 group errors
  */
  public setV5Gr(index): void {
    this.errMess = "";
    this.selectedGr = index;
    this.actualDescr = this.selectedErr.desc[index].desc;
  }
  /***/

  /*
  * Name: sendUpdatedError
  * Description: send error with description updated
  */
  public sendUpdatedError(): void {
    if (!this.selectedErr['desc']) this.selectedErr['desc'] = [];
    if (!this.selectedGr && this.isGenError())
      this.selectedErr.desc[0] = { code: "gen", name: "générique", desc: this.actualDescr};
    else if ((!this.selectedGr && !this.isGenError()) || !this.selectedErr.desc[this.selectedGr])
      this.errMess = "Please select or add a group"
    else
      this.selectedErr.desc[this.selectedGr].desc = this.actualDescr
    this.editV5Error()
    .then(() => {
      this.editMode = false;
    });
  }
  /***/

  /*
  * Name: getV5Errors
  * Description: get all v5 error from server
  */
  public getV5Errors(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.token) {
        this.api.request('GET', '/api/admin/v5errors/'+this.token, null)
        .then(async (ret) => {
          this.v5Errors = ret;
          return resolve()
        }).catch((err) => {
          if (err == 'Forbiden') {
            this.connected = false;
            this.token = null;
          }
          return reject(err)
        })
      } else {
        this.connected = false;
        return reject()
      }
    })
  }
  /***/

  /*
  * Name: addV5Error
  * Description: post a new v5 error to the server
  */
  public addV5Error(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.token) {
        for (let i = 0; i < this.v5Errors.length; i++) {
          if (this.modal.code == this.v5Errors[i]) {
            this.errMess = "errAlreadyDeclared";
            return;
          }
        }
        let toSend = {
          code: this.modal.code,
          desc: this.getEmptyDesc()
        }
        this.api.request('POST', '/api/admin/v5errors/'+this.token, toSend)
        .then(async (ret) => {
          toSend["_id"] = ret
          this.v5Errors.push(toSend);
          this.modal = null;
          this.errMess = "";
          return resolve()
        }).catch((err) => {
          if (err == 'Forbiden') {
            this.connected = false;
            this.token = null;
          }
          return reject(err)
        })
      } else {
        this.connected = false;
        return reject()
      }
    })
  }
  /***/

  /*
  * Name: addGroupToV5Error
  * Description: post a new v5 group error to the error
  */
  public addGroupToV5Error(): Promise<void> {
    if (!this.selectedErr['desc']) this.selectedErr['desc'] = [];
    for (let i = 0; i < this.selectedErr.desc.length; i++) {
      if (this.selectedErr.desc[i].code == this.modal.code) {
        this.errMess = "grAlreadyDeclared";
        return;
      }
    }
    let toSend = {
      code: this.modal.code,
      name: this.modal.name,
      desc: "-"
    }
    this.selectedErr.desc.push(toSend)
    this.editV5Error()
  }
  /***/

  /*
  * Name: editV5Error
  * Description: put an error from it Mongo ID
  */
  public editV5Error(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.token) {
        this.api.request('PUT', '/api/admin/v5errors/'+this.selectedErr._id+"/"+this.token, this.selectedErr)
        .then(async (ret) => {
          this.modal = null;
          this.errMess = "";
          return resolve()
        }).catch((err) => {
          if (err == 'Forbiden') {
            this.connected = false;
            this.token = null;
          }
          return reject(err)
        });
      } else {
        this.connected = false;
        return reject()
      }
    })
  }
  /***/

  /*
  * Name: isGenError
  * Description: return true if the selected error is a generic error
  */
  public isGenError(): boolean {
    if (this.selectedErr && this.selectedErr["code"]) {
      let c = this.selectedErr.code[2];
      switch (c) {
        case "0": case "1": case "2": case "3":
        case "9": case "A": case "B": case "F":
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  }
  /***/

  /*
  * Name: isGenErrorCode
  * Description: return true if the code is from a generic error
  */
  public isGenErrorCode(code): boolean {
    if (code && code[2]) {
      let c = code[2];
      switch (c) {
        case "0": case "1": case "2": case "3":
        case "9": case "A": case "B": case "F":
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  }
  /***/

  /*
  * Name: getEmptyDesc
  * Description: return the empty description tab when created errors
  */
  public getEmptyDesc(): any {
    if (this.isGenErrorCode(this.modal.code)) {
      return [{ code: "gen", name: "générique", desc: "-"}]
    } else {
      return [
        // ** Core Resources (Start 0x01 -> 0x0F) MAX 14
        { code: "gen", name: "générique", desc: "-"},
        { code: "0xFF", name: "GR_NONE", desc: "-"},
        { code: "0x00", name: "GR_SYSEVT", desc: "-"},
        { code: "0x01", name: "GR_VR_IO", desc: "-"},
        { code: "0x02", name: "GR_VR_ANA", desc: "-"},
        { code: "0x03", name: "GR_VR_ANA8", desc: "-"},
        { code: "0x04", name: "GR_VR_ANA16", desc: "-"},
        { code: "0x05", name: "GR_VR_ANA32", desc: "-"},
        { code: "0x06", name: "GR_UNITE", desc: "-"},
        { code: "0x07", name: "GR_VR_STR", desc: "-"},
        { code: "0x08", name: "GR_VR_STR32", desc: "-"},
        { code: "0x09", name: "GR_VR_STR64", desc: "-"},
        { code: "0x0A", name: "GR_VR_STR128", desc: "-"},
        { code: "0x0B", name: "GR_VR_STR256", desc: "-"},
        { code: "0x0C", name: "GR_VR_STR512", desc: "-"},

        // ** Application Objects (Start 0x10 -> 0x3F) MAX 45
        { code: "0x10", name: "GR_FUNCTION", desc: "-"},
        { code: "0x11", name: "GR_PUSH", desc: "-"},
        { code: "0x12", name: "GR_MAIL", desc: "-"},
        { code: "0x13", name: "GR_COUNT", desc: "-"},
        { code: "0x14", name: "GR_TIMER", desc: "-"},
        { code: "0x15", name: "GR_COMPARATOR", desc: "-"},
        { code: "0x16", name: "GR_TOPIC", desc: "-"},
        { code: "0x17", name: "GR_CALENDAR", desc: "-"},
        { code: "0x18", name: "GR_PRESET", desc: "-"},
        { code: "0x19", name: "GR_THERMOSTAT", desc: "-"},
        { code: "0x1A", name: "GR_SWITCH", desc: "-"},
        { code: "0x1B", name: "GR_FADER", desc: "-"},
        { code: "0x1C", name: "GR_PINGWD", desc: "-"},
        { code: "0x1D", name: "GR_PLANNING ", desc: "-"},
        { code: "0x1E", name: "GR_PARSER_JSON ", desc: "-"},
        { code: "0x1F", name: "GR_IPX800V4 ", desc: "-"},

        // ** EBX (RS485) Extensions (Start 0x40 -> 0x5F) MAX 30
        { code: "0x40", name: "GR_EBX", desc: "-"},
        { code: "0x41", name: "GR_X8R", desc: "-"},
        { code: "0x42", name: "GR_X24D", desc: "-"},
        { code: "0x43", name: "GR_X8D", desc: "-"},
        { code: "0x44", name: "GR_X4VR", desc: "-"},
        { code: "0x45", name: "GR_XTHL", desc: "-"},
        { code: "0x46", name: "GR_XDMX", desc: "-"},
        { code: "0x47", name: "GR_XPWM", desc: "-"},
        { code: "0x48", name: "GR_X010V", desc: "-"},
        { code: "0x49", name: "GR_X4FP", desc: "-"},
        { code: "0x4A", name: "GR_XDIMMER", desc: "-"},
        { code: "0x4B", name: "GR_XDISPLAY", desc: "-"},
        { code: "0x4C", name: "GR_X400", desc: "-"},
        { code: "0x4D", name: "GR_X200", desc: "-"},
        { code: "0x4E", name: "GR_XDALI", desc: "-"},
        { code: "0x4F", name: "GR_XDALI_BALLAST", desc: "-"},
        { code: "0x50", name: "GR_XPOOL", desc: "-"},

        // ** EXT (UART) Extensions (Start 0x60 -> 0x7F) MAX 30
        { code: "0x60", name: "GR_EXT_ENO", desc: "-"},
        { code: "0x61", name: "GR_EXT_ENO_F6", desc: "-"},
        { code: "0x63", name: "GR_EXT_ENO_D2", desc: "-"},
        { code: "0x62", name: "GR_EXT_ENO_D201", desc: "-"},
        { code: "0x64", name: "GR_EXT_ENO_D5", desc: "-"},
        { code: "0x65", name: "GR_EXT_ENO_A5", desc: "-"},
        { code: "0x66", name: "GR_EXT_ENO_A5Gen", desc: "-"},
        { code: "0x67", name: "GR_EXT_ENO_D205", desc: "-"},
        { code: "0x68", name: "GR_EXT_ENO_D214", desc: "-"},
        { code: "0x69", name: "GR_EXT_ENO_A512", desc: "-"},
        { code: "0x6A", name: "GR_EXT_ENO_A520", desc: "-"},
        { code: "0x6B", name: "GR_EXT_ENO_D203", desc: "-"},// spare 0x6B -> 0x6F (x)

        { code: "0x70", name: "GR_MODBUS", desc: "-"},
        { code: "0x71", name: "GR_SYS_MODBUS", desc: "-"},

        // ** Plugins (Start 0x80 -> 0x8F) MAX 15
        { code: "0x80", name: "GR_PLUGINS", desc: "-"},
        { code: "0x81", name: "GR_WEATHER", desc: "-"},
        { code: "0x82", name: "GR_HUE", desc: "-"},
        { code: "0x83", name: "GR_HUE_Light", desc: "-"},

        // ** System components (Start 0x90 -> 0xBF) MAX 45
        { code: "0x90", name: "GR_IPX ", desc: "-"},
        { code: "0x91", name: "GR_SYSTEM", desc: "-"},
        { code: "0x92", name: "GR_NETWORK ", desc: "-"},
        { code: "0x93", name: "GR_CERT ", desc: "-"},
        { code: "0x94", name: "GR_SECURITY ", desc: "-"},
        { code: "0x95", name: "GR_TIME ", desc: "-"},
        { code: "0x96", name: "GR_AUTH ", desc: "-"},
        { code: "0x97", name: "GR_SMTP ", desc: "-"},
        { code: "0x98", name: "GR_DDNS ", desc: "-"},
        { code: "0x99", name: "GR_MQTT ", desc: "-"},
        { code: "0x9A", name: "GR_GCESERVER ", desc: "-"},
        { code: "0x9B", name: "GR_SNTP ", desc: "-"},
        { code: "0x9C", name: "GR_SETTINGS ", desc: "-"},
        { code: "0x9D", name: "GR_SMARTGCE ", desc: "-"},// spare 0x9E -> 0x9F (2)

        { code: "0xA0", name: "GR_CONNECTOR ", desc: "-"},
        { code: "0xA1", name: "GR_SCENE ", desc: "-"},
        { code: "0xA2", name: "GR_RULE ", desc: "-"},
        { code: "0xA3", name: "GR_USERS ", desc: "-"},
        { code: "0xA4", name: "GR_APIKEY ", desc: "-"},
        { code: "0xA5", name: "GR_DASH", desc: "-"},
        { code: "0xA6", name: "GR_LIVEVIEW", desc: "-"},
        { code: "0xA7", name: "GR_DASHWS ", desc: "-"},
        { code: "0xA8", name: "GR_THEME ", desc: "-"},
        { code: "0xA9", name: "GR_SYSLOG ", desc: "-"},
        { code: "0xAA", name: "GR_FILEUPLOAD", desc: "-"},
        { code: "0xAB", name: "GR_GEST_EBX ", desc: "-"},// spare 0xA9 -> 0xAF (x)// spare 0xB0 -> 0xBF (15)

        // ** RESERVED for Sub_e (Start 0xC0 -> 0xCF) MAX 15
        // ** RESERVED for Sub_e (Start 0xD0 -> 0xDF) MAX 15
        // ** RESERVED for Sub_e (Start 0x20 -> 0x2F) MAX 15
        // ** THIS PLAGE IS RESERVED FOR GROUP USE BY FRONT (Start 0xE0 -> 0xEF) ** //
        { code: "0xE0", name: "GR_CONTACT ", desc: "-"},

        // ** Others (Start 0xF0 -> 0xFE) MAX 14 (0xFF reserved for NONE)
        { code: "0xF0", name: "GR_UTILS ", desc: "-"},
        { code: "0xF1", name: "GR_STACKIP ", desc: "-"}, // Group can report only ORYX error code from TCP/IP stack
        { code: "0xF2", name: "GR_DRIVERS", desc: "-"},
        { code: "0xF3", name: "GR_PERIOD_TASK ", desc: "-"},
        { code: "0xF4", name: "GR_EVENT_TASK", desc: "-"},
        { code: "0xF5", name: "GR_RULE_TASK ", desc: "-"},
        { code: "0xF6", name: "GR_EBX_TASK ", desc: "-"},
        { code: "0xF7", name: "GR_EXT_TASK ", desc: "-"},
        { code: "0xF8", name: "GR_BSP_TASK ", desc: "-"},// spare 0xF6 -> 0xFE (x)

        // ** Sub-Groups (Start 0xC0 -> 0xDF) MAX 30
        // ** Sub-Groups (Start 0x20 -> 0x2F) MAX 15
        // IPX object sub-groups
        { code: "0xC0", name: "Sub_Din ", desc: "-"},
        { code: "0xC1", name: "Sub_Relay_Command ", desc: "-"},
        { code: "0xCE", name: "Sub_Relay_State ", desc: "-"},
        { code: "0xC2", name: "Sub_ADC ", desc: "-"},
        { code: "0xC3", name: "Sub_Reset ", desc: "-"},
        { code: "0xCA", name: "Sub_Fuse ", desc: "-"},
        { code: "0xCB", name: "Sub_Power ", desc: "-"},
        { code: "0xDC", name: "Sub_Collector_Input ", desc: "-"},
        { code: "0xDD", name: "Sub_Collector_Ouput ", desc: "-"},
        { code: "0xDE", name: "Sub_Collector_Ouput_state ", desc: "-"},

        // X8R object sub-groups
        { code: "0xC4", name: "Sub_X8R_Relay_Command ", desc: "-"},
        { code: "0xCF", name: "Sub_X8R_Relay_State ", desc: "-"},
        { code: "0xC5", name: "Sub_X8R_longPush ", desc: "-"},

        // X24D object sub-groups
        { code: "0xC6", name: "Sub_X24D_Din ", desc: "-"},
        { code: "0xC7", name: "Sub_X24D_longPush ", desc: "-"},

        // X8D object sub-groups
        { code: "0xC8", name: "Sub_X8D_Din ", desc: "-"},
        { code: "0xC9", name: "Sub_X8D_longPush ", desc: "-"},

        // X400 object sub-groups
        { code: "0xCC", name: "Sub_X400_CT ", desc: "-"},
        { code: "0xCD", name: "Sub_X400_ANA ", desc: "-"},// spare 0xAA -> 0xAF (6)

        // TIMER object sub-groups
        { code: "0xD0", name: "Sub_TIMER_DELAY", desc: "-"},
        { code: "0xD1", name: "Sub_TIMER_TEMPO", desc: "-"},
        { code: "0xD2", name: "Sub_TIMER_CHRONO", desc: "-"},
        { code: "0xD3", name: "Sub_TIMER_PULSE", desc: "-"},
        { code: "0xD4", name: "Sub_TIMER_BLINKER", desc: "-"},

        // TOPIC object sub-groups
        { code: "0xD5", name: "Sub_TOPIC_PUB ", desc: "-"},
        { code: "0xD6", name: "Sub_TOPIC_SUB ", desc: "-"},
        { code: "0xD7", name: "Sub_TOPIC_ALL ", desc: "-"},

        // SWITCH object sub-groups
        { code: "0xD8", name: "Sub_TELERUPTOR", desc: "-"},
        { code: "0xD9", name: "Sub_MONOSTABLE ", desc: "-"},
        { code: "0xDA", name: "Sub_LONGCLIC ", desc: "-"},
        { code: "0xDB", name: "Sub_DOUBLECLIC ", desc: "-"},
        { code: "0x23", name: "Sub_RS ", desc: "-"},

        // MODBUS object sub_groups
        { code: "0xDF", name: "Sub_MODBUS_READ ", desc: "-"},
        { code: "0x20", name: "Sub_MODBUS_WRITE ", desc: "-"},

        // PLANNING object sub_groups
        { code: "0x21", name: "Sub_PLANNING_TOR ", desc: "-"},
        { code: "0x22", name: "Sub_PLANNING_ANA ", desc: "-"},

        // X8R object sub-groups
        { code: "0x24", name: "Sub_XPOOL_Relay_State ", desc: "-"},
        { code: "0x25", name: "Sub_XPOOL_Input ", desc: "-"},
        { code: "0x26", name: "Sub_XPOOL_Analog ", desc: "-"},
        { code: "0x27", name: "Sub_XPOOL_Notif ", desc: "-"}
      ]
    }
  }
  /***/
/***/
}
