/******************************************************************************
 *  @Filename: is-connected.guard.ts
 *  @Date: 28/10/2021
 *  @Author: Jbristhuille
 *
 *  Description: Check user connection
 *******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Component
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: canActivate
*/

/* IMPORT */
/* Angular/Ionic */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
/***/
/* Services */
import {SessionService} from '../../services/session/session.service';
/***/

/***/

@Injectable({
  providedIn: 'root'
})
export class IsConnectedGuard implements CanActivate {
  constructor(private router: Router,
              private session: SessionService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean {
    if (!this.session.isConnected()) {
      this.session.connect(null);
    } // try auto-connect

    if (this.session.isConnected()) {
      return route.routeConfig.path === 'login' ? this.router.parseUrl('/dashboard') : true;
    } else {
      return (route.routeConfig.path === 'login' || route.routeConfig.path === 'alexa') ? true : this.router.parseUrl('/login');
    }
  }
}
