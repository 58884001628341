/******************************************************************************
 *  @Filename: alexa-login.component.ts
 *  @Date: 05-09-2023
 *  @Author: Claudio
 *
 *  Description: Alexa Login page variables and function
 *******************************************************************************/

/* SUMMARY
* IMPORT
  * Angular/Ionic
  * Services
  * Component
  * Node modules
* VARIABLES
* FUNCTIONS
  * Name: connect
  * Name: openForgetPassModal
*/

/* IMPORT */
/* Angular/Ionic */
import {Component, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
/* Activated route */
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
/***/
/* Services */
import {SessionService} from '../../../services/session/session.service';
import {WsService} from '../../../services/ws/ws.service';
import {DataService} from '../../../services/data/data.service';
import {ApiService} from '../../../services/api/api.service';
/***/
/* Component */
import {ForgetPasswordComponent} from '../forget-password/forget-password.component';

import {DashboardComponent} from '../../../dashboard/components/dashboard/dashboard.component';
import {DevicesComponent} from '../../../devices/components/devices/devices.component';
/***/

/***/

@Component({
  selector: 'app-alexa-login',
  templateUrl: './alexa-login.component.html',
  styleUrls: ['./alexa-login.component.scss'],
})
export class AlexaLoginComponent implements OnInit {
// section variables
  /* VARIABLES */
  public user: any = {
    email: '',
    passwd: ''
  };

  public onLoad: boolean;

  protected query: any;
  protected code = '';

  constructor(private router: Router,
              private session: SessionService,
              private ws: WsService,
              private alertController: AlertController,
              private modalController: ModalController,
              private api: ApiService,
              private dash: DashboardComponent,
              private devi: DevicesComponent,
              private data: DataService,
              private translate: TranslateService,
              private route: ActivatedRoute) {
  }

  /***/

  /* FUNCTIONS */

  // section init
  ngOnInit() {
    this.query = this.route.snapshot.queryParams;
    this.data.destroy();
  }

  /*
  * Name: connect
  * Description: Connect user
  *
  * Args:
  * - email (String): User email
  * - passwd (String): User password
  */

  // section connect
  public connect(email, passwd): void {
    this.onLoad = true;
    email = email.toLowerCase();
    const user = {login: email, passwd};
    const data = {
      user,
      query: this.query
    };
    this.api.request('POST', 'https://smartgce.com/alexa-oauth/link', data).then((ret) => {
      if (ret.url === undefined) {
        if (this.session.connect(ret)) {
          this.router.navigate(['/dashboard']).then(() => {
            this.dash.init();
          });
        } else {
          this.alertController.create({
            cssClass: 'system-alert',
            header: this.translate.instant('alert.loginErr'),
            message: this.translate.instant('alert.loginErrMess'),
            buttons: ['OK']
          }).then((alert) => {
            alert.present();
          });
        }
      } else {
        location.href = ret.url; // Redirect user
      }
    }).catch((err) => {
      if (err === 'Password not match') {
        err = 'passNotMatch';
      } else if (err === 'This account has already been linked.') {
        err = 'accountAlreadyLinked';
      } else if (err === 'User not found') {
        err = 'userNotFound';
      } else {
        err = 'internal';
      }
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.loginErr'),
        message: this.translate.instant('alert.' + err),
        buttons: ['OK']
      }).then((alert) => {
        alert.present();
      });

      console.error('error: ', err);
    }).then(() => {
      this.onLoad = false;
    });
  }

  /***/

  /*
  * Name: openForgetPassModal
  * Description: Open edition modal
  */

  // section forget
  public openForgetPassModal(): void {
    this.modalController.create({
      component: ForgetPasswordComponent,
      cssClass: 'system-modal',
      componentProps: {}
    }).then(async (modal) => {
      modal.present();
    });
  }


  protected makeRandom(lengthOfCode: number = 32) {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-abcdefghijklmnopqrstuvwxyz';
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /***/
  /***/
}
