/******************************************************************************
*  @Filename: privacy-policy.component.ts
*  @Date: 25-04-2022
*  @Author: Adrien Lanco
*
*  Description: privacy policy component
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Component
    * Node modules
  * VARIABLES
  * FUNCTIONS
*/


/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit } from '@angular/core';
  import { CommonModule } from '@angular/common';
  import { ActivatedRoute } from '@angular/router';
  /***/
/***/

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  language: string = "en"

  ngOnInit() {
    this.route.queryParams
    .subscribe(params => {
      if (params.lang == "en" || params.lang == "fr")
        this.language = params.lang;
    });
  }
}
