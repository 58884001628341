/******************************************************************************/
/*  Name: ...                                                                 */
/*  Date: ...                                                                 */
/*  Author: ...                                                               */
/*                                                                            */
/*  Description: ...                                                          */
/******************************************************************************/

/* SUMMARY
  * Nest
  * Pipe
*/

/* Nest */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'
import {TranslateModule} from '@ngx-translate/core';
/***/

/* Components */
import { AccountComponent } from './components/account/account.component';
import { EditUserComponent } from './modals/edit-user/edit-user.component';
import { UnlinkComponent } from './modals/unlink/unlink.component';
/***/

/* Pipe */
import { ToDatePipe } from '../pipes/to-date/to-date.pipe';
/***/

@NgModule({
  declarations: [
    AccountComponent,
    EditUserComponent,
    UnlinkComponent,
    ToDatePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  exports: [
    AccountComponent
  ]
})
export class AccountModule {}
