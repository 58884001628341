/******************************************************************************
*  @Filename: link.component.ts
*  @Date: 15/11/2021
*  @Author: Jbristhuille
*
*  Description: Link thirdparty service variables and functions
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: connect
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit } from '@angular/core';
  import { AlertController } from '@ionic/angular';
  import { Router, ActivatedRoute } from '@angular/router';
  import {TranslateService} from '@ngx-translate/core';
  /***/

  /* Services */
  import { SessionService } from '../../services/session/session.service';
  import { ApiService } from '../../services/api/api.service';
  /***/
/***/

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent implements OnInit {

/* VARIABLES */
  private query: any;

  public user: any = {
    email: '',
    passwd: ''
  };

  public onLoad: boolean;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private session: SessionService,
              private alertController: AlertController,
              private api: ApiService,
              private translate: TranslateService) {
  }
/***/

/* FUNCTIONS */
  ngOnInit() {
    this.query = this.route.snapshot.queryParams;
  }

  /*
  * Name: connect
  * Description: Connect user
  *
  * Args:
  * - email (String): User email
  * - passwd (String): User password
  */
  public connect(email, passwd): void {
    this.onLoad = true;

    let data = {
      user: {login: email, passwd: passwd},
      query: this.query
    };

    this.api.request('POST', '/'+data.query['type']+'/link', data).then((ret) => {
      location.href = ret.url; // Redirect user
    }).catch((err) => {
      if (err == "Password not match") err = "passNotMatch";
      else if (err == "This account has already been linked.") err = 'accountAlreadyLinked';
      else if (err == "User not found") err = "userNotFound";
      else err = "internal";
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.loginErr'),
        message: this.translate.instant('alert.'+err),
        buttons: ['OK']
      }).then((alert) => {alert.present()});

      console.error('error: ', err);
    }).then(() => {
      this.onLoad = false;
    });
  }
  /***/
/***/
}
