/******************************************************************************
*  @Filename: app.module.ts
*  @Date: 28/10/2021
*  @Author: Jbrissthuille
*
*  Description: App module entry
*******************************************************************************/

/* SUMMARY
  * Angular
  * Modules
*/

/* Angular */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
/***/

/* Modules */
import { AppRoutingModule } from './app-routing.module';
import { LoginModule } from './login/login.module';
import { ServicesModule } from './services/services.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DevicesModule } from './devices/devices.module';
import { AccountModule } from './account/account.module';
import { ThirdpartyModule } from './thirdparty/thirdparty.module';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
/***/

/* Component */
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AdminComponent } from './admin/admin.component';
import { PrivacyPolicyComponent } from './policy/privacy-policy/privacy-policy.component';
/***/

import { SortablejsModule } from 'ngx-sortablejs';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    AdminComponent,
    PrivacyPolicyComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    LoginModule,
    ServicesModule,
    DashboardModule,
    DevicesModule,
    AccountModule,
    ThirdpartyModule,
    SortablejsModule.forRoot({ animation: 150 }),
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
