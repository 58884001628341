/******************************************************************************
*  @Filename: remove-device.component.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description: Confirm remove devices modal variables and functions
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Component
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: delete
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit, Input } from '@angular/core';
  import { ModalController, AlertController } from '@ionic/angular';
  import {TranslateService} from '@ngx-translate/core';
  /***/

  /* Services */
  import { ApiService } from '../../../services/api/api.service';
  /***/
/***/

@Component({
  selector: 'app-remove-device',
  templateUrl: './remove-device.component.html',
  styleUrls: ['./remove-device.component.scss'],
})
export class RemoveDeviceComponent implements OnInit {

/* VARIABLES */
  @Input() public device: string;
  @Input() public userId: string;

  constructor(private modalController: ModalController,
              private alertController: AlertController,
              private api: ApiService,
              private translate: TranslateService) {
  }
/***/

/* FUNCTIONS */
  ngOnInit() {
  }

  /*
  * Name: delete
  * Description: Confirm device deletion
  *
  * Args:
  * - id (String): Device id to remove
  */
  public delete(device): void {
    this.api.request('DELETE', '/api/users/'+this.userId+'/devices/'+device.type+'/'+device._id, null)
    .then((ret) => {
      this.modalController.dismiss(ret, 'saved'); // Return registered device
    }).catch((err) => {
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.deDelErr'),
        message: this.translate.instant('alert.internal'),
        buttons: ['OK']
      }).then((alert) => {alert.present()});

      console.error(err);
    });
  }
  /***/
/***/
}
