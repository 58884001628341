/******************************************************************************
*  @Filename: data-selector.component.ts
*  @Date: 21-01-2022
*  @Author: Adrien Lanco
*
*  Description:
*******************************************************************************/

import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-v4-data-selector',
  templateUrl: './v4-data-selector.component.html',
  styleUrls: ['./v4-data-selector.component.scss'],
})
export class V4DataSelectorComponent implements OnInit {
  @Input() dataName: string;
  @Input() data: string;
  @Output() onDataChange = new EventEmitter<string>();

  private data_id: string;
  private dataType: string;

  constructor() { }

  ngOnInit() {
    if (this.data) {
      if (this.data[0] == 'R') {
        this.dataType = 'r';
      } else if (this.data[0] == 'V') {
        if (this.data[1] == 'I') this.dataType = 'vi';
        if (this.data[1] == 'O') this.dataType = 'vo';
      }
      this.data_id = this.data;
    }
  }

}
