/******************************************************************************
*  @Filename: to-date.pipe.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description: Convert unix epoch timestamp to date
*******************************************************************************/

/* SUMMARY
* IMPORT
  * Angular/Ionic
  * Services
  * Component
  * Node modules
* VARIABLES
* FUNCTIONS
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Pipe, PipeTransform } from '@angular/core';
  /***/
/***/

@Pipe({
  name: 'toDate'
})
export class ToDatePipe implements PipeTransform {
/* FUNCTIONS */
  transform(value: number): string {
    try {
      let time = new Date(value*1000);
      let date = time.toLocaleDateString();
      let hour = time.toLocaleTimeString();

      return hour+' - '+date;
    } catch (err) {
      console.error(err);
      return '';
    }
  }
/***/
}
