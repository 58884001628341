/******************************************************************************
*  @Filename: edit-widget.component.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description:
*******************************************************************************/

/******************************************************************************/
/*  Name: edit-widget.component.html                                          */
/*  Date: 19/11/2021                                                          */
/*  Author: Jbristhuille                                                      */
/*                                                                            */
/*  Description: Widget edition modal variables and functions                 */
/******************************************************************************/

/* SUMMARY
  * Nest
  * Services
  * Name: save
  * Name: remove
*/

/* Nest */
import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
/***/

/* Services */
import { SessionService } from '../../../services/session/session.service';
import { ApiService } from '../../../services/api/api.service';
import { CmdService } from '../../../services/cmd/cmd.service';
import { DataService } from '../../../services/data/data.service';

import { ModalService } from '../services/modal/modal.service';
/***/


import {V4DataSelectorComponent} from "../components/widgetV4/v4-data-selector/v4-data-selector.component";
import {V5DataSelectorComponent} from "../components/widgetV5/v5-data-selector/v5-data-selector.component";

/* Node modules */
import * as _ from 'lodash';
/***/

@Component({
  selector: 'app-edit-widget',
  templateUrl: './edit-widget.component.html',
  styleUrls: ['./edit-widget.component.scss'],
})
export class EditWidgetComponent implements OnInit {
  @Input() private user_id: string;
  @Input() public widget: any;
  @Input() public isRemove: boolean;
  public devices: any;

  public loading: boolean = true;
  public onLoad: boolean;
  public onRemove: boolean = false;
  public typeList: any;
  public actualTraits: any;

  public openIds: boolean = false;
  public onGetIdError: string = null;

  public v4SingleBlind: boolean = true;

  constructor(private alertController: AlertController,
              private modalController: ModalController,
              private session: SessionService,
              private api: ApiService,
              private cmd: CmdService,
              private data: DataService,
              private translate: TranslateService,
              public modalService: ModalService) {
  }

  ngOnInit() {
    this.onRemove = this.isRemove;
    if (!this.onRemove) {
      this.data.getUserDevices()
      .then((ret) => {
        this.devices = ret; // Return registered device
        this.widget.device = _.find(this.devices, (el) => {return _.isEqual(el.mac, this.widget.mac)});
        this.loading = false;
        if (this.widget.device)
          this.onDeviceInit(this.widget.device);
      }).catch((err) => {
        if (err == "Widget not found") err = "widgetNotFound";
        else err = "internal";
        this.alertController.create({
          cssClass: 'system-alert',
          header: this.translate.instant('alert.devicesLoadingErr'),
          message: this.translate.instant('alert.internal'),
          buttons: ['OK']
        }).then((alert) => {alert.present()});

        console.error(err);
      });
    }
  }


  public checkAbleTrait(): void {
    for (const [trait, value] of Object.entries(this.widget.traits))
      for (let i = 0; i < this.widget.data_id[trait].length; i++)
        if (value == false)
          this.widget.data_id[trait][i] = "";
  }

  /*
  * Name: save
  * Description: Save widget configuration
  *
  * Args:
  * - widget (Object): Widget data
  */
  public save(widget): void {
    this.onLoad = true;
    this.checkAbleTrait();
    let user = this.session.getUser();

    let put = _.cloneDeep(widget);
    put.mac = widget.device.mac;
    put.deviceType = widget.device.type;
    put.googlehome = this.cmd.genGoogleDevice(widget, user._id);

    delete put.traits;
    delete put.device;
    delete put.value;

    this.api.request('PUT', '/api/users/'+this.user_id+'/widgets/'+put._id, put)
    .then((ret) => {
      this.modalController.dismiss(ret, 'saved'); // Return saved device
    }).catch((err) => {
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.widgetSaveErr'),
        message: this.translate.instant('alert.internal'),
        buttons: ['OK']
      }).then((alert) => {alert.present()});

      console.error(err);
    }).then(() => {
      this.onLoad = false;
    });
  }
  /***/

  /*
  * Name: remove
  * Description: Remove widget
  *
  * Args:
  * - widget (String): Widget data
  */
  public remove(widget): void {
    this.onLoad = true;

    this.api.request('DELETE', '/api/users/'+this.user_id+'/widgets/'+widget._id, null)
    .then((ret) => {
      this.modalController.dismiss(null, 'saved');
    }).catch((err) => {
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.widgetRemErr'),
        message: this.translate.instant('alert.internal'),
        buttons: ['OK']
      }).then((alert) => {alert.present()});

      console.error(err);
    }).then(() => {
      this.onLoad = false;
    });
  }
  /***/


  public onDeviceInit(device) {
    if (device.type == "ipx800v4") {
      this.typeList = [
        { type: "OUTLET",   name: "Outlet" },
        { type: "LIGHT",    name: "Light" },
        { type: "BLINDS",   name: "Blinds" },
      ]
    } else if (device.type == "ipx800v5") {
      this.typeList = [
        { type: "OUTLET",   name: "Outlet" },
        { type: "LIGHT",    name: "Light" },
        { type: "BLINDS",   name: "Blinds" },
      ]
    }
    this.onTypeInit(this.widget.type);
  }

  public onDeviceSelected(device) {
    if (this.widget.device != device) {
      this.widget.device = device;
      this.widget.type = null;
      if (device.type == "ipx800v4") {
        this.typeList = [
          { type: "OUTLET",   name: "Outlet" },
          { type: "LIGHT",    name: "Light" },
          { type: "BLINDS",   name: "Blinds" },
        ]
      } else if (device.type == "ipx800v5") {
        this.typeList = [
          { type: "OUTLET",   name: "Outlet" },
          { type: "LIGHT",    name: "Light" },
          { type: "BLINDS",   name: "Blinds" },
        ]
      }
    }
  }

  public onTypeInit(type) {
    if (type) {
      this.widget.traits = {};
      this.widget.type = type;
      if (this.widget.type == "BLINDS" && this.widget.deviceType == "ipx800v5" && this.widget.data_id["OpenClose"].length == 2)
        this.widget.data_id["OpenClose"] = [this.widget.data_id["OpenClose"][0]];

      if (this.widget.type == "BLINDS" && this.widget.deviceType == "ipx800v4"
       && this.widget.data_id["OpenClose"] && this.widget.data_id["OpenClose"][1])
        this.v4SingleBlind = false;

      let traits = this.cmd.getTraitsFromType(this.widget.device.type, this.widget.type);
      for (let i = 0; i < traits.length; i++) {
        if (!this.widget.data_id[traits[i].name]) this.widget.data_id[traits[i].name] = [];
        if (this.widget.data_id[traits[i].name].length == 0) this.widget.data_id[traits[i].name][0] = '';
        if (this.widget.data_id[traits[i].name][0] != '')
          this.widget.traits[traits[i].name] = true;
        else
          this.widget.traits[traits[i].name] = false;
      }
      this.actualTraits = traits;
    }
  }

  public onTypeSelected(type) {
    if (type) {
      this.widget.data_id = {};
      this.widget.traits = {};
      this.widget.type = type;
      let traits = this.cmd.getTraitsFromType(this.widget.device.type, this.widget.type);
      for (let i = 0; i < traits.length; i++) {
        this.widget.data_id[traits[i].name] = [];
        this.widget.traits[traits[i].name] = traits[i].required;
        for (let j = 0; j < traits[i].data.length; j++) {
          this.widget.data_id[traits[i].name][j] = "";
        }
      }
      this.actualTraits = traits;
    }
  }


  /*
  * Name: remove
  * Description: Remove widget
  *
  * Args:
  * - widget (String): Widget data
  */
  public dismiss(): void {
      this.modalController.dismiss(null, 'dismiss');
  }
  /***/

  public invalidField(): boolean {
    let target = document.getElementById("gce-edit-widget-config");
    if (target) {
      let invalid = target.getElementsByClassName("gce-invalid-field");
      if (invalid.length > 0) return true;
      else return false;
    } else return true;
  }

  /*
  * Name: isInvalid
  * Description: check if the widget is not in error
  *
  * Return: true if the widget is invalid
  */
  public isInvalid(): boolean {
    if (!this.widget.name ||
        !this.widget.cmd ||
        !this.widget.device ||
        !this.widget.type) {
          return true
    }
    let traits = this.cmd.getTraitsFromType(this.widget.device.type, this.widget.type);
    for (let i = 0; i < traits.length; i++) {
      if (this.widget.traits[traits[i].name] == true) {
        if (!this.widget.data_id[traits[i].name][0] ||
            this.widget.data_id[traits[i].name][0] == "" )
          return true
      }
    }
    return false;
  }
  /***/

  /*
  * Name: getV5Ids
  * Description: get ids from v5
  *              from the output of the v5 data selector
  */
  public getV5Ids(event) {
    this.onGetIdError = null;
    this.widget.objSource = event;
    this.modalService.getV5Ids(this.widget, event)
    .catch((err) => {
      for (let i = 0; i < this.actualTraits.length; i++) {
        if (this.actualTraits[i].required && this.widget.traits[this.actualTraits[i].name]) {
          this.widget.data_id[this.actualTraits[i].name] = [null];
        }
      }
      if (err == "Timeout")
        this.onGetIdError = "GetIdsTimeout";
      else
        this.onGetIdError = "GetIdsNotFound";
    })
  }
  /***/

  public onV4SingleBlindChange(): void {
    this.v4SingleBlind = !this.v4SingleBlind;
    if (this.v4SingleBlind == true) {
      this.widget.data_id["OpenClose"][1] = null
    }
  }
}
