/******************************************************************************
*  @Filename: step-progress.component.ts
*  @Date: 03-05-2022
*  @Author: Adrien Lanco
*
*  Description: step progress component
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Node modules
    * Name: save
*/

/* IMPORT */
  /* Angular/Ionic */
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
  /***/
/***/

@Component({
  selector: 'app-step-progress',
  templateUrl: './step-progress.component.html',
  styleUrls: ['./step-progress.component.scss'],
})
export class StepProgressComponent implements OnInit, OnChanges {

/* VARIABLES */
  @Input() public nbStep: any; // Widget data value
  @Input() public actualStep: any; // Widget data value

  public value: number = 0; // Widget data value
/***/

/* FUNCTIONS */
  constructor() { }

  ngOnInit() {
    this.setValue()
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.actualStep) {
      this.setValue()
    }
  }

  /*
  * Name: setValue
  * Description: set display value from actualStep and nbStep
  *
  */
  private setValue() {
    if (this.actualStep > this.nbStep)
      this.value = 1;
    else
      this.value = this.actualStep / this.nbStep;
  }
  /***/
/***/
}
