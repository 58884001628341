/******************************************************************************
*  @Filename: devices.component.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description: Devices page variables and functions
*******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Component
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: refresh
    * Name: getDevicesDescriptor
    * Name: getUserDevices
    * Name: openAddDeviceModal
    * Name: openEditDeviceModal
    * Name: openRemoveDeviceModal
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit } from '@angular/core';
  import { AlertController, ModalController } from '@ionic/angular';
  import {TranslateService} from '@ngx-translate/core';
  /***/

  /* Services */
  import { SessionService } from '../../../services/session/session.service';
  import { DataService } from '../../../services/data/data.service';
  import { ApiService } from '../../../services/api/api.service';
  import { WsService } from '../../../services/ws/ws.service';
  /***/

  /* Components */
  import { AddDeviceComponent } from '../../modals/add-device/add-device.component';
  import { EditDeviceComponent } from '../../modals/edit-device/edit-device.component';
  import { RemoveDeviceComponent } from '../../modals/remove-device/remove-device.component';
  /***/

  /* Node modules */
  import * as _ from 'lodash';
  /***/
/***/

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit {

/* VARIABLES */
  public onLoad: boolean = true;

  public descriptors: any[] = [];
  public editMode = false;

  constructor(private alertController: AlertController,
              private modalController: ModalController,
              private session: SessionService,
              private data: DataService,
              private ws: WsService,
              private api: ApiService,
              private translate: TranslateService) {
  }
/***/

/* FUNCTIONS */
  async init() {
    await this.refresh(null);
    this.onLoad = false;
  }

  async ngOnInit() {
    await this.init()
  }

  /*
  * Name: refresh
  * Description: Refresh user devices list
  */
  public async refresh(e): Promise<void> {
    await this.data.initData("devices");
    this.descriptors = await this.getDevicesDescriptor(); // Get device descriptors
    let tmp = this.data.userDevices; // Get devices data
    this.data.userDevices = [];

    tmp.forEach((device) => { // Merge descriptor and device data
      let decriptor = _.find(this.descriptors, {type: device.type});

      if (decriptor) {
        device['config'] = decriptor.config;
        this.data.userDevices.push(device);
      }
    });

    if (e) e.target.complete();
  }
  /***/

  /*
  * Name: getDevicesDescriptor
  * Description: Get descriptors to print and configure devices
  *
  * Return (Object[]): List of devices descriptors
  */
  private getDevicesDescriptor(): Promise<any[]> {
    return new Promise((resolve) => {
      this.api.request('GET', '/api/devices/descriptors', null)
      .then((ret) => {
        return resolve(ret);
      }).catch((err) => {
        if (err == "Unauthorized") this.session.unauthorized();
        else
          this.alertController.create({
            cssClass: 'system-alert',
            header:  this.translate.instant('alert.devDescrLoadingErr'),
            message: this.translate.instant('alert.internal'),
            buttons: ['OK']
          }).then((alert) => {alert.present()});

        console.error(err);
        return resolve([]);
      });
    });
  }
  /***/

  /*
  * Name: getUserDevices
  * Description: Get devices associated to connected user
  *
  * Return (Object[]): User devices
  */
  private getUserDevices(): Promise<any[]> {
    return new Promise((resolve) => {
      let user = this.session.getUser();

      this.api.request('GET', '/api/users/'+user._id+'/devices', null)
      .then((ret) => {
        return resolve(ret);
      }).catch((err) => {
        if (err == "Unauthorized") this.session.unauthorized();
        else
          this.alertController.create({
            cssClass: 'system-alert',
            header: this.translate.instant('alert.devicesLoadingErr'),
            message: this.translate.instant('alert.internal'),
            buttons: ['OK']
          }).then((alert) => {alert.present()});

        console.error(err);
        return resolve([]);
      });
    });
  }
  /***/

  /*
  * Name: openAddDeviceModal
  * Description: Open add device modal
  */
  public openAddDeviceModal(): void {
    let user = this.session.getUser();

    this.modalController.create({
      component: AddDeviceComponent,
      cssClass: 'system-modal',
      componentProps: {
        'descriptors': this.descriptors,
        'userId': user._id
      }
    }).then(async (modal) => {
      modal.present();

      modal.onWillDismiss().then((ret) => {
        if (ret.role == 'saved') { // Only after device registration
          this.editMode = false;
          this.onLoad = true;
          this.refresh(null).then(() => {this.onLoad = false}); // Refresh devices list
        }
      });
    });
  }
  /***/

  /*
  * Name: openEditDeviceModal
  * Description: Open edit device modal
  *
  * Args:
  * - device (Object): Device data
  */
  public openEditDeviceModal(device): void {
    let user = this.session.getUser();

    this.modalController.create({
      component: EditDeviceComponent,
      cssClass: 'system-modal',
      componentProps: {
        'userId': user._id,
        'device': device
      }
    }).then(async (modal) => {
      modal.present();

      modal.onWillDismiss().then((ret) => {
        if (ret.role == 'saved') { // Only after device registration
          this.editMode = false;
          this.onLoad = true;
          this.refresh(null).then(() => {this.onLoad = false}); // Refresh devices list
        }
      });
    });
  }
  /***/

  /*
  * Name: openRemoveDeviceModal
  * Description: Open remove confirmation modal
  *
  * Args:
  * - evt (Event): Event data
  */
  public openRemoveDeviceModal(evt, id): void {
    let user = this.session.getUser();

    this.modalController.create({
      component: RemoveDeviceComponent,
      cssClass: 'system-modal',
      componentProps: {
        'userId': user._id,
        'id': id
      }
    }).then(async (modal) => {
      modal.present();

      modal.onWillDismiss().then((ret) => {
        if (ret.role == 'saved') { // Only after device registration
          this.editMode = false;
          this.onLoad = true;
          this.refresh(null).then(() => {this.onLoad = false}); // Refresh devices list
        }
      });
    });

    evt.preventDefault();
  }
  /***/
/***/
}
