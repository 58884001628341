/******************************************************************************
*  @Filename: widget-outlet.component.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description:
*******************************************************************************/

/******************************************************************************/
/*  Name: widget-io.component.ts                                              */
/*  Date: 19/11/2021                                                          */
/*  Author: Jbristhuille                                                      */
/*                                                                            */
/*  Description: Widget IO content variables and functions                    */
/******************************************************************************/

/* SUMMARY
  * IMPORT
    * Angular/Ionic
    * Services
    * Node modules
  * VARIABLES
  * FUNCTIONS
    * Name: updateState
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, Input, OnInit } from '@angular/core';
  /***/

  /* Services */
  import { WsService } from '../../../../../services/ws/ws.service';
  /***/
/***/

@Component({
  selector: 'app-widget-outlet',
  templateUrl: './widget-outlet.component.html',
  styleUrls: ['./widget-outlet.component.scss'],
})
export class WidgetOutletComponent implements OnInit {

/* VARIABLES */
  @Input() public widget: any; // Widget data value

  constructor(private ws: WsService) {
  }
/***/

/* FUNCTIONS */
  ngOnInit() {
    this.widget.value = {};
    this.widget.value["OnOff"] = false;
  }

  /*
  * Name: updateState
  * Description: Update widget state
  *
  * Args:
  * - state (Boolean): Current state
  *
  * Return (Boolean): Return new state
  */
  public updateState(): void {
    let id;
    if (!this.widget["onLoad"])this.widget["onLoad"] = {};
    this.widget.onLoad["OnOff"] = !this.widget.value["OnOff"];

    if (this.widget.data_id["OnOff"][1] && this.widget.value["OnOff"] == false) {
      id = this.widget.data_id["OnOff"][1];
    } else {
      id = this.widget.data_id["OnOff"][0];
    }
    let toSend = {
        from: "ihm",
        to: this.widget.deviceType,
        mac: this.widget.mac,
        _id: id,
        value: !this.widget.value["OnOff"],
        cmd: 'set'
    }
    this.ws.send(toSend);
  }
  /***/
/***/
}
