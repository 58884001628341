/******************************************************************************
*  @Filename: login.component.ts
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description: Login page variables and function
*******************************************************************************/

/* SUMMARY
* IMPORT
  * Angular/Ionic
  * Services
  * Component
  * Node modules
* VARIABLES
* FUNCTIONS
  * Name: connect
  * Name: openForgetPassModal
*/

/* IMPORT */
  /* Angular/Ionic */
  import { Component, OnInit } from '@angular/core';
  import { AlertController, ModalController } from '@ionic/angular';
  import { Router } from '@angular/router';
  import {TranslateService} from '@ngx-translate/core';
  /***/

  /* Services */
  import { SessionService } from '../../../services/session/session.service';
  import { WsService } from '../../../services/ws/ws.service';
  import { DataService } from '../../../services/data/data.service';
  import { ApiService } from '../../../services/api/api.service';
  /***/

  /* Component */
  import { ForgetPasswordComponent } from '../forget-password/forget-password.component';

  import { DashboardComponent } from '../../../dashboard/components/dashboard/dashboard.component';
  import { DevicesComponent } from '../../../devices/components/devices/devices.component';
  /***/
/***/

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
// section variables
/* VARIABLES */
  public user: any = {
    email: '',
    passwd: ''
  };

  public onLoad: boolean;

  constructor(private router: Router,
              private session: SessionService,
              private ws: WsService,
              private alertController: AlertController,
              private modalController: ModalController,
              private api: ApiService,
              private dash: DashboardComponent,
              private devi: DevicesComponent,
              private data: DataService,
              private translate: TranslateService) {
  }
/***/

/* FUNCTIONS */
  ngOnInit() {
    this.data.destroy();
  }

  /*
  * Name: connect
  * Description: Connect user
  *
  * Args:
  * - email (String): User email
  * - passwd (String): User password
  */
  public connect(email, passwd): void {
    this.onLoad = true;
    email = email.toLowerCase()
    let user = {login: email, passwd: passwd};

    this.api.request('POST', '/api/users/connect', user).then(async (ret) => {
      if (this.session.connect(ret)) {
        this.router.navigate(['/dashboard']).then(() => {
          this.dash.init();
        });
      } else {
        this.alertController.create({
          cssClass: 'system-alert',
          header: this.translate.instant('alert.loginErr'),
          message: this.translate.instant('alert.loginErrMess'),
          buttons: ['OK']
        }).then((alert) => {alert.present()});
      }
    }).catch((err) => {
      if (err == 'User not found') err = "userNotFound";
      else if (err = "Password not match") err = "passNotMatch";
      else err = "internal"
      this.alertController.create({
        cssClass: 'system-alert',
        header: this.translate.instant('alert.loginErr'),
        message: this.translate.instant('alert.'+err),
        buttons: ['OK']
      }).then((alert) => {alert.present()});
    }).then(() => {
      this.onLoad = false;
    });
  }
  /***/

  /*
  * Name: openForgetPassModal
  * Description: Open edition modal
  */
  public openForgetPassModal(): void {
    this.modalController.create({
      component: ForgetPasswordComponent,
      cssClass: 'system-modal',
      componentProps: {}
    }).then(async (modal) => {
      modal.present();
    });
  }
  /***/
/***/
}
